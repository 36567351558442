import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import api from "../services/api";
import AddCardInput from "./AddCardInput";
import Button from "./Button";
import InputsDedicated from "./InputsDedicated";
import InputsInternational from "./InputsInternational";
import InputsInternationalFtl from "./InputsInternationalFtl";
import InputsNational from "./InputsNational";
import RemoveCardInput from "./RemoveCardInput";

const CardFreightView = ({
  value,
  onSubmit,
  id,
  type,
  typeRoute,
  setListItens,
  typeSelect,
  routeDetails,
}) => {
  const [number, setNumber] = useState([]);
  const [data, setData] = useState([]);

  const [dataUpdate, setDataUpdate] = useState([]);
  const [getInfo, setGetinfo] = useState([]);

  const [update, setUpdate] = useState(false);
  const [stock, setStock] = useState([]);
  const [idOne, setIdOne] = useState("");

  const uploadExcel = () => {
    let arr = [1];
    if (!typeRoute) {
      return null;
    } else {
      if (typeRoute === "Rodoviário internacional FTL") {
        if (stock.length > 0) {
          setNumber([1]);

          console.log(stock);
          stock.map((item, i) => {
            const arrData = data;

            const internationalNew = {
              peso_ranges_kg: item["Faixas de Peso ( kg )"],
              pallet_quantity_pbr_100x120x120cm:
                item["Quantidade pallet PBR   100x120x120cm"],
              cubagem: item["Cubagem"],
              type_of_vehicles: item["Tipo de veiculos"],
              origin_city_state: item["ORIGEM (cidade/uf)"],
              origin_address: item["Endereço origem"],
              country: item["PAIS DE ORIGEM"],
              destino_city_state: item["DESTINO  (cidade/uf)"],
              destination_address: item["Endereço destino"],
              country1: item["PAIS DE DESTINO"],
              freight_cost_per_route: item["custo frete por rota"],
              freight_sale: item["Frete Venda"],
              crt_adm_fee: item["Taxa ADM por CRT"],
              tx_inspection_senasa_per_crt: item["TX Inspeção SENASA por crt"],
              gris_fca_usd: item[" GRIS FCA-USD"],
              ad_valorem_fca_usd: item["Ad valorem  FCA-USD"],
              tx_eadi: item["TX EADI"],
              pick_up_sp_51_to_100_km: item["PICK UP SP 51 a 100 km"],
              pick_up_sp_101_to_150_km: item["PICK UP SP 101 a 150 km"],
              pick_up_sp_151_to_200_km: item["PICK UP SP 151 a 200 km"],
              exw_fees: item["Taxas EXW"],
              incoterms_brazil_fees: item["Taxas (EXW | FCA | FAS | FOB)"],
              exw_fees_argentina: item["Taxas EXW – Argentina vs Brasil"],
              customs_fees_arg: item["Taxas Aduana_Arg"],
              argentina_clearance_fees: item["Taxas desembaraço Argentina"],
              incoterms_argentina_fees:
                item["Taxas (CPT | CIP | CFR | CIF | DAP | DPU | DDP)"],
              extra_cost: item["Custo Extra"],
              carga_imo: item["CARGA IMO"],
            };

            arrData[i] = internationalNew;

            setData(arrData);
          });
          const filter = data.filter((item) => item !== undefined);
          setData(filter);

          const notUndefined = data.filter(
            (item, index) => index !== undefined
          );
          setNumber(notUndefined.map((item, index) => index + 1));
        } else {
          setNumber(arr);
        }
      }

      if (typeRoute === "Rodoviário internacional LTL") {
        if (stock.length > 0) {
          setNumber([1]);

          const origins = [];

          let point = 0;
          stock.map((item, i) => {
            if (item["Peso mínimo"] === "DESTINOS") {
              point = i;
            }
          });

          if (point === 0) {
            toast.error("Arquivo com o formato incorreto", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }

          stock.map((item, i) => {
            if (i > point + 1) {
              const dataOrigins = {
                origin: item["Peso mínimo"],
                destiny: item["Peso máximo"],
                storage: item["Cubagem mínima"],
                collectionAddress: item["Cubagem maxíma"],
                collectionAddressImo: item["Frete  standard"],
                deliveryAddress: item["Frete  premmiun"],
                deliveryAddressImo: item["Taxa ADM"],
                city: item["Taxa Inspeção senasa"],
                cityImo: item["Taxa consolidação por pallete ou solta"],
                finalDestination: item["Gris"],
                discount: item["Ad valorem"],
                increase: item["Gris armazém"],
                routeType: item["Taxa eadi"],
                coinType: item["PICK UP Até 50KM (NACIONAL)"],
                countryOrigin: item["PICK UP - 51 a 100 km (NACIONAL)"],
                countryDestination: item["PICK UP 101 a 150 km (NACIONAL)"],
              };

              origins.push(dataOrigins);
            }
          });

          stock.map((item, i) => {
            const arrData = data;
            if (i < point) {
              console.log(item["TIPO DA MOEDA"]);
              const newDataInternational = {
                initial_weight_range: item["Peso mínimo"],
                final_weight_range: item["Peso máximo"],
                initial_cubing: item["Cubagem mínima"],
                final_cubing: item["Cubagem maxíma"],
                standard_transit_time: item["Frete  standard"],
                premium_transit_time: item["Frete  premmiun"],
                admin_fee_per_crt: item["Taxa ADM"],
                senasa_inspection_fee_per_crt: item["Taxa Inspeção senasa"],
                consolidation_fee_per_pallete:
                  item["Taxa consolidação por pallete ou solta"],
                gris_fca_usd: item["Gris"],
                ad_valorem_fca_usd: item["Ad valorem"],
                ad_valorem_gris_eadi_fca: item["Gris armazém"],
                eadi_fee: item["Taxa eadi"],
                pick_up_up_to_50km_sp: item["PICK UP Até 50KM (NACIONAL)"],
                pick_up_51_to_100km_sp:
                  item["PICK UP - 51 a 100 km (NACIONAL)"],
                pick_up_101_to_150km_sp:
                  item["PICK UP 101 a 150 km (NACIONAL)"],
                pick_up_151_to_200km_sp:
                  item["PICK UP 151 a 200 km (NACIONAL)"],

                delivery_to_50km: item["DELIVERY SP Até 30KM (INTERNACIONAL)"],
                delivery_51_to_100km:
                  item["DELIVERY SP 31 a 60 km (INTERNACIONAL)"],
                delivery_101_to_150km:
                  item["DELIVERY SP 61 a 90 km (INTERNACIONAL)"],
                delivery_151_to_200km:
                  item["DELIVERY  91 a 120 km (INTERNACIONAL)"],

                exw_fees: item["Taxas EXW"],
                additional_insurance_percentage_argentina:
                  item["Taxas EXW – Argentina vs Brasil"],
                customs_fees_argentina: item["Taxas Aduana_Arg"],
                argentina_clearance_fees: item["Taxas desembaraço Argentina"],

                incoterms_brazil_fees: item["Taxas (EXW | FCA | FAS | FOB)"],
                incoterms_argentina_fees:
                  item["Taxas (CPT | CIP | CFR | CIF | DAP | DPU | DDP)"],

                due_clearance_required:
                  item["Emissão DUE-Desembaraço ? Sim ou Não"],
                co_certificate_of_origin_required:
                  item["Emissão CO_certif origem ? Sim ou Não"],
                additional_co_certificate_of_origin_required:
                  item["Emissão CO_certif origem adicional ? Sim ou Não"],
                helper_fee: item["Taxa Por ajudante"],
                extra_cost: item["Custo Extra"],
                hazardous_cargo: item["CARGA IMO"],
                stacking_factor: item["fator empilhamento _ SIM OU NÃO"],
                bank_exchange_rate: 0,
                final_freight_value_usd: 0,
                origin: JSON.stringify(origins),
              };

              arrData[i] = newDataInternational;
              setData(arrData);
            }
          });
          const filter = data.filter((item) => item !== undefined);
          setData(filter);

          const notUndefined = data.filter(
            (item, index) => index !== undefined
          );
          setNumber(notUndefined.map((item, index) => index + 1));
        } else {
          setNumber(arr);
        }
      }

      if (typeRoute === "Rodoviário nacional") {
        setNumber([]);

        stock.filter((item, i) => {
          if (i === 0) {
            if (item["Destino"]) {
              const formactOrigin = item["Destino"]
                .split(":")[1]
                .split("/")[0]
                .trim();

              const originValue = formactOrigin;
              localStorage.setItem("@MAXITRANS_ORIGIN_EXCEL:", originValue);
            }
          }

          if (i > 2) {
            const day = Number(item["transit-time"].split("+")[1]);

            const arrData = data;
            const newDataNational = {
              origin_trade_route: localStorage.getItem(
                "@MAXITRANS_ORIGIN_EXCEL:"
              ),
              uf: item["UF"],
              destination_trade_route: item["Destino"],
              transit_time: day,
              up_to_10kg: item["Até 10kg"],
              up_to_20kg: item["Até 20kg"],
              up_to_30kg: item["Até 30kg"],
              up_to_50kg: item["Até 50kg"],
              up_to_70kg: item["Até 70kg"],
              up_to_100kg: item["Até 100kg"],
              up_to_150kg: item["Até 150kg"],
              up_to_200kg: item["Até 200kg"],
              up_to_250kg: item["Até 250kg"],
              excess_kg: item["excedente"],
              cte_dispatch: item["despacho"],
              sefaz_tax: item["sefaz"],
              shipping_value: item["frete"],
              gris: item["gris"],
              icms: item["icms"],
              toll: item["pedagio"],
            };
            arrData[i] = newDataNational;
            setData(arrData);
          }
        });
        localStorage.removeItem("@MAXITRANS_ORIGIN_EXCEL:");

        const filter = data.filter((item) => item !== undefined);
        setData(filter);

        const notUndefined = data.filter((item, index) => index !== undefined);
        setNumber(notUndefined.map((item, index) => index + 1));
      }

      if (typeRoute === "Rodoviário dedicado") {
        const data = stock.map((item, i) => {
          return {
            vehicle: item["VEÍCULO"],
            km_max: item["KM/(MAXIMO)"],
            output: item["SAÍDA"],
            km_loaded_above_maximum:
              item["R$ KM/CARREGADO (ACIMA DO KM MAXIMO)"],
            weight_capacity: item["CAP.PESO"],
            cubage: item["CUBAGEM"],
            axes: item["EIXOS"],
            vehicle_type: item["TIPO DE VEICULO"],
            calculation_qualp: item["CALCULO DO QUALP"],
            profit_margin_output: item["MARGEM DE LUCRO | SAIDA"],
            profit_margin_above_maximum_km:
              item["MARGEM DE LUCRO | ACIMA DO KM MAXIMO"],
            number_vehicle: item["NUMERO DO VEICULO"],
            profit_margin_which_qualp: item["MARGEM DE LUCRO | QUALP"],
            gris: item["GRIS %"],
            ad_valorem: item["AD VALOREM %"],
          };
        });

        setData(data);
        console.log(data);
        setNumber(data.map((item, index) => index + 1));
      }
    }
  };

  const addRoute = () => {
    if (type === "update") {
      setGetinfo([...getInfo, {}]);
    }
    /* NUMBER CARD */
    const lastItem = number[number.length - 1];
    setNumber([...number, lastItem + 1]);
  };

  const getFreigthAll = async () => {
    if (id) {
      console.log(typeSelect);
      if (typeSelect === "quotation") {
        const response = await api.get(`/quotation/${id}`);

        const { rota } = response.data;

        console.log(rota);

        setGetinfo(rota);
        setDataUpdate(rota);

        const lastItem = number[number.length - 1];
        rota.map((item) => setNumber([...number, lastItem + 1]));
      } else {
        const response = await api.get(`/vehicle/${id}`);
        const { vehicles } = response.data;
        setGetinfo(vehicles);
        console.log(value === "Rodoviário dedicado");
      }
    }
  };

  const removeRoute = () => {
    if (type === "update") {
      const lastItem = dataUpdate.length - 1;

      const removeLastItem = getInfo.slice(0, lastItem);

      setGetinfo(removeLastItem);
    } else {
      const lastItem = number[number.length - 1];

      const removeLastItemArray = number.filter((item) => {
        if (item != 1) {
          if (lastItem != item) {
            return item;
          }
        }
      });

      setNumber([1, ...removeLastItemArray]);
    }
  };

  useEffect(() => {
    const idDiv1 = uuidv4();

    setIdOne(idDiv1);

    setListItens((prevList) => [...prevList, idDiv1]);
  }, []);

  useEffect(() => {
    if (id) {
      getFreigthAll();
    }
  }, [id]);

  useEffect(() => {
    if (stock.length > 0) {
      uploadExcel();
    }
  }, [stock, typeRoute]);

  /* STYLES */
  const container = {
    width: "100%",
    backgroundColor: "#F9F9F9",
    display: "flex",
    justifyContent: "center",
    paddingTop: "39px",
    marginBottom: "50px",
  };

  const boxCard = {
    border: "1px solid #d7d7d7",
    width: "1000px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "24px",
  };

  const card = {
    display: "flex",
    gap: "18px",
    alignItems: "center",
  };

  const numberBox = {
    minWidth: "34px",
    minHeight: "34px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "60px",
    backgroundColor: "var(--color-primary)",
    color: "#fff",
  };

  const boxAddOrRemove = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "-webkit-sticky",
    position: "sticky",
    top: "0",
    marginTop: "20px",
  };

  console.log(dataUpdate);

  return (
    <div className="boxContainerCard" style={container}>
      <div style={boxCard}>
        <div id={idOne} style={{ overflowY: "hidden" }}>
          {getInfo.length > 0
            ? getInfo.map((item, index) => {
                return (
                  <>
                    {(value === "Rodoviário nacional" && (
                      <>
                        <div style={card}>
                          <div style={numberBox}>{index + 1}</div>

                          <InputsNational
                            id={id}
                            getInfo={item}
                            index={index}
                            numberIndex={getInfo}
                            setData={setDataUpdate}
                            data={dataUpdate}
                          />
                        </div>
                      </>
                    )) ||
                      (value === "Rodoviário dedicado" && (
                        <>
                          <div style={card}>
                            <div style={numberBox}>{index + 1}</div>

                            <InputsDedicated
                              id={id}
                              getInfo={item}
                              index={index}
                              numberIndex={getInfo}
                              setData={setDataUpdate}
                              data={dataUpdate}
                            />
                          </div>
                        </>
                      )) ||
                      (value === "Rodoviário internacional LTL" && (
                        <>
                          <div style={card}>
                            <div style={numberBox}>{index + 1}</div>

                            <InputsInternational
                              update={true}
                              numberIndex={index}
                              length={getInfo.length}
                              setData={setDataUpdate}
                              data={dataUpdate}
                            />
                          </div>
                        </>
                      )) ||
                      (value === "Rodoviário internacional FTL" && (
                        <>
                          <div style={card}>
                            <div style={numberBox}>{index + 1}</div>

                            <InputsInternationalFtl
                              update={true}
                              numberIndex={index}
                              length={getInfo.length}
                              setData={setDataUpdate}
                              data={dataUpdate}
                            />
                          </div>
                        </>
                      ))}
                  </>
                );
              })
            : number.map((item, index) => {
                return (
                  <>
                    {(value === "Rodoviário nacional" && (
                      <>
                        <div style={card}>
                          <div style={numberBox}>{item}</div>

                          <InputsNational
                            id={id}
                            index={index}
                            numberIndex={number}
                            setData={setData}
                            data={data}
                          />
                        </div>
                      </>
                    )) ||
                      (value === "Rodoviário dedicado" && (
                        <>
                          <div style={card}>
                            <div style={numberBox}>{index + 1}</div>

                            <InputsDedicated
                              id={id}
                              index={index}
                              numberIndex={getInfo}
                              setData={setData}
                              data={data}
                            />
                          </div>
                        </>
                      )) ||
                      (value === "Rodoviário internacional LTL" && (
                        <>
                          <div style={card}>
                            <div style={numberBox}>{item}</div>

                            <InputsInternational
                              numberIndex={index}
                              setData={setData}
                              data={data}
                            />
                          </div>
                        </>
                      )) ||
                      (value === "Rodoviário internacional FTL" && (
                        <>
                          <div style={card}>
                            <div style={numberBox}>{item}</div>

                            <InputsInternationalFtl
                              numberIndex={index}
                              setData={setData}
                              data={data}
                            />
                          </div>
                        </>
                      ))}
                  </>
                );
              })}
        </div>
        <div style={boxAddOrRemove}>
          <AddCardInput stock={stock} setStock={setStock} onClick={addRoute} />

          {(type === "update" && getInfo.length > 1 && (
            <RemoveCardInput onClick={removeRoute} />
          )) ||
            (type != "update" && number.length > 1 && (
              <RemoveCardInput onClick={removeRoute} />
            ))}
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            width="195px"
            height="45px"
            background={"var(--color-primary)"}
            borderRadius="60px"
            marginTop="0"
            name="Salvar"
            color={"var(--white)"}
            onClick={() => {
              if (type === "update") {
                onSubmit(getInfo);
              } else {
                onSubmit(data);
              }
            }}
            border="none"
          />
        </div>
      </div>
    </div>
  );
};

export default CardFreightView;
