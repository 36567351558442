import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CardDetailsView from "../../components/CardDetailsView";
import CardFreightView from "../../components/CardFreightView";
import ContainerInputSearchAndButton from "../../components/ContainerInputSearchAndButton";
import HeaderDashboard from "../../components/HeaderDashboard";
import ModalContainer from "../../components/ModalContainer";
import ModalOptions from "../../components/ModalOptions";
import ScrollBotton from "../../components/ScrollBotton";
import ShippingCards from "../../components/ShippingCards";
import Sidebar from "../../components/Sidebar";
import { UserContext } from "../../context/User";
import ThowColumLayout from "../../layouts/ThowColumLayout";
import api from "../../services/api";

const DashboardAdm = () => {
  const {
    permissionVisibility,
    getProfile,
    requestMade,
    setPermissionVisibility,
  } = useContext(UserContext);
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [initial, setInitial] = useState("true");
  const [detailsTable, setDetailsTable] = useState(false);

  const [newTable, setNewTable] = useState(false);
  const [selectInput, setSelectInput] = useState("Rodoviário nacional");
  const [nameQuotation, setNameQuotation] = useState("");

  const [listDiscount, setListDiscount] = useState([]);
  const [listDiscountFreigth, setListDiscountFreigth] = useState([]);
  const [listDiscountVehicle, setListDiscountVehicle] = useState([]);

  const [idDetails, setIdDetails] = useState("");
  const [nameDelete, setNameDelete] = useState("");
  const [idDelete, setIdDelete] = useState("");
  const [typeDelet, setTypeDelet] = useState("");

  const [listItens, setListItens] = useState([]);

  const [routeDetails, setRouteDetails] = useState([]);
  let div = document.getElementById("test");

  const container = {
    maxWidth: "100%",
    paddingRight: "4.125rem",
    paddingTop: "2.6875rem",
    paddingLeft: "3.375rem",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "20px",
  };

  const boxCard = {
    maxWidth: "1000px",
    display: "flex",
    gap: "21px",
    flexWrap: "wrap",
    marginTop: "36px",
    width: "100%",
  };

  const onSubmitCreateTable = async (data) => {
    try {
      if (data.length === 0) {
        toast.error("Adicione ao menos um item na tabela", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false;
      } else if (selectInput === "Rodoviário dedicado") {
        try {
          const dataUpload = {
            name: nameQuotation,
            type: selectInput,
            users: listDiscount,
            data,
          };
          await api.post("/type/vehicle", dataUpload);

          toast.info(
            "A tabela de cotação por veículos foi atualizada com sucesso!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } catch (error) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        return false;
      } else {
        const newDataFormact = {
          name: nameQuotation,
          type: selectInput,
          users: listDiscount,
          quotation: data,
        };

        await api.post("/quotations", newDataFormact);

        toast.success("Tabela criada com sucesso", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => {
          setNewTable(false);
          setInitial(true);
        }, 500);
      }
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const onSubmitUpdateTable = async (data) => {
    console.log(data);
    try {
      const newDataFormact = {
        name: nameQuotation,
        type: selectInput,
        users: listDiscount,
        quotation: data,
      };

      console.log(newDataFormact);
      await api.patch(`/quotation/${idDetails}`, newDataFormact);

      toast.info("Tabela atualizada com sucesso", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setTimeout(() => {
        setNewTable(false);
        setInitial(true);
      }, 500);
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getDiscountsFreigth = async () => {
    try {
      const response = await api.get("/quotations");
      const responseVehicle = await api.get("/vehicle");

      setListDiscountFreigth(response.data);
      setListDiscountVehicle(responseVehicle.data);
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const onGetDetails = async (
    id,
    typeOption,
    { name = "", visibility = "", type = "", rota = "" } = {}
  ) => {
    setIdDetails(id);

    try {
      if (typeOption === "quotation") {
        setNameQuotation(name);
        setListDiscount(visibility);
        setSelectInput(type);
        setRouteDetails(rota);
      } else {
        const response = await api.get(`/vehicle/${id}`);
        const { name, visibility, type, rota } = response.data;
        // console.log(visibility);

        setNameQuotation(name);
        setListDiscount(visibility);
        setSelectInput(type);
      }
    } catch (error) {}
  };

  const onDeleteTable = async () => {
    try {
      if (typeDelet === "quotation") {
        await api.delete(`/quotation/${idDelete}`);
        setModal(false);
        setNameDelete("");
        setIdDelete("");
        getDiscountsFreigth();
      } else {
        await api.delete(`/vehicle/${idDelete}`);
        setModal(false);
        setNameDelete("");
        setIdDelete("");
        getDiscountsFreigth();
      }
    } catch (error) {}
  };

  const syncScroll = () => {
    div = document.getElementById("test");

    // Iterar sobre os elementos do loop e definir o scroll esquerdo igual ao do elemento principal
    if (listItens && listItens.length) {
      listItens.forEach((item) => {
        let divLoop = document.getElementById(item);
        console.log(divLoop, 8080);

        if (divLoop) {
          divLoop.scrollLeft = div.scrollLeft;
        }
      });
    }
  };

  const controlPermission = async () => {
    // Verifica se já foi feito essa requisição
    if (requestMade) {
      if (!permissionVisibility) navigate("/dashboard/manage/admin");
    } else {
      await getProfile();
    }
  };

  useEffect(() => {
    controlPermission();
  }, [permissionVisibility, requestMade]);

  useEffect(() => {
    const div = document.getElementById("test");
    if (div && listItens) {
      div.addEventListener("scroll", syncScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener("scroll", syncScroll);
      }
    };
  }, [listItens]);

  useEffect(() => {
    // getDiscountsFreigth();

    setInitial(true);
  }, []);

  useEffect(() => {
    if (initial === true) {
      setNameQuotation("");
      setSelectInput("Rodoviário nacional");
      getDiscountsFreigth();
      setListDiscount([]);
    }
  }, [modal, initial, detailsTable, newTable]);

  useEffect(() => {
    console.log(selectInput);
  }, [selectInput]);

  return permissionVisibility ? (
    <ThowColumLayout
      page={"DashboardAdm"}
      colum1={<Sidebar page="DashboardAdm" access="adm" />}
      colum2={
        initial ? (
          <div
            style={{
              backgroundColor: "#F9F9F9",
              minHeight: "100vh",
            }}
          >
            <HeaderDashboard name="Tabelas de frete" adm={true} />

            <div className="boxContainerCard" style={container}>
              <ContainerInputSearchAndButton
                excel={true}
                table={null}
                onClick={() => {
                  setInitial(false);
                  setDetailsTable(false);
                  setNewTable(true);
                }}
              />
              <div style={boxCard}>
                {listDiscountFreigth.map((item) => {
                  return (
                    <>
                      <ShippingCards
                        title={item.name}
                        visibility={item.visibility}
                        type={item.type}
                        id={item.id}
                        onClick={() => {
                          setSelectInput(item.type);
                          setInitial(false);
                          setDetailsTable(true);
                          onGetDetails(item.id, "quotation", item);
                          setTypeDelet("quotation");
                        }}
                        openModal={() => {
                          setNameDelete(item.name);
                          setIdDelete(item.id);
                          setModal(true);
                          setTypeDelet("quotation");
                        }}
                      />
                    </>
                  );
                })}
                {listDiscountVehicle.map((item) => {
                  return (
                    <>
                      <ShippingCards
                        title={item.name}
                        visibility={item.discouns}
                        type={item.type}
                        id={item.id}
                        onClick={() => {
                          setSelectInput(item.type);
                          setInitial(false);
                          setDetailsTable(true);
                          onGetDetails(item.id, "vehicle");
                          setTypeDelet("vehicle");
                        }}
                        openModal={() => {
                          setNameDelete(item.name);
                          setIdDelete(item.id);
                          setModal(true);
                          setTypeDelet("vehicle");
                        }}
                      />
                    </>
                  );
                })}

                <ModalContainer
                  isActive={modal}
                  closeModal={() => setModal(!modal)}
                >
                  <ModalOptions
                    nameDelete={nameDelete}
                    isActive={modal}
                    type="delete"
                    closeModal={() => setModal(!modal)}
                    onClick={onDeleteTable}
                  />
                </ModalContainer>
              </div>
            </div>
          </div>
        ) : (
          (detailsTable && (
            <div
              style={{
                backgroundColor: "#F9F9F9",
                minHeight: "100vh",
              }}
            >
              <HeaderDashboard
                back={true}
                onClick={() => setInitial(true)}
                name="Detalhes da tabela"
                adm={true}
              />

              <CardDetailsView
                id={idDetails}
                listDiscount={listDiscount}
                setListDiscount={setListDiscount}
                value={nameQuotation}
                details={{
                  name: nameQuotation,
                  visibility: listDiscount,
                  type: selectInput,
                  route: routeDetails,
                }}
                onChange={(e) => setNameQuotation(e.target.value)}
                valueSelect={selectInput}
                typeSelect={typeDelet}
                onChangeSelect={(e) => setSelectInput(e.target.value)}
              />
              <ScrollBotton width={"8000px"} />
              <CardFreightView
                type={"update"}
                typeRoute={selectInput}
                id={idDetails}
                onSubmit={onSubmitUpdateTable}
                value={selectInput}
                typeSelect={typeDelet}
                setListItens={setListItens}
              />
            </div>
          )) ||
          (newTable && (
            <div
              style={{
                backgroundColor: "#F9F9F9",
                minHeight: "100vh",
              }}
            >
              <HeaderDashboard
                back={true}
                onClick={() => setInitial(true)}
                name="Nova tabela"
                adm={true}
              />

              <CardDetailsView
                listDiscount={listDiscount}
                setListDiscount={setListDiscount}
                value={nameQuotation}
                onChange={(e) => setNameQuotation(e.target.value)}
                valueSelect={selectInput}
                onChangeSelect={(e) => setSelectInput(e.target.value)}
              />
              <ScrollBotton width={"2850px"} typeRoute={selectInput} />
              <CardFreightView
                typeRoute={selectInput}
                onSubmit={onSubmitCreateTable}
                value={selectInput}
                setListItens={setListItens}
              />
            </div>
          ))
        )
      }
    ></ThowColumLayout>
  ) : (
    <></>
  );
};

export default DashboardAdm;
