import { useEffect, useState } from "react";
import Input from "./Input";

const InputsDedicated = ({
  setData,
  data,
  numberIndex,
  getInfo,
  index,
  id,
}) => {
  /* NATIONAL STATES*/
  const [vehicle, setVehicle] = useState(data[index]?.vehicle);
  const [kmMax, setKmMax] = useState(data[index]?.km_max);
  const [output, setOutput] = useState(data[index]?.output);

  const [kmChargedUpMaximum, setKmChargedUpMaximum] = useState(
    data[index]?.km_charged_up_maximum
  );
  const [kmLoadedAboveMaximum, setKmLoadedAboveMaximum] = useState(
    data[index]?.km_loaded_above_maximum
  );
  const [kmEmpty, setKmEmpty] = useState(data[index]?.km_empty);

  const [capPeso, setCapPeso] = useState(data[index]?.cap_peso);
  const [cubage, setCubage] = useState(data[index]?.cubage);
  const [axes, setAxes] = useState(data[index]?.axes);

  const [vehicleType, setVehicleType] = useState(data[index]?.vehicle_type);
  const [averageVehicleSpeed, setAverageVehicleSpeed] = useState(
    data[index]?.average_vehicle_speed
  );

  const [margemQualp, setMargemQualp] = useState(
    data[index]?.profit_margin_which_qualp
  );

  const [averageQualp, setAverageQualp] = useState(
    data[index]?.profit_margin_which_qualp
  );
  const [gris, setGris] = useState(data[index]?.gris);
  const [advalorem, setAdvalorem] = useState(data[index]?.ad_valorem);

  // const convertToBRL = async (amount, setAmount) => {
  //   const price = Number(amount).toLocaleString("pt-BR", {
  //     style: "currency",
  //     currency: "BRL",
  //   });

  //   setAmount(price);
  // };

  // const convertValueInput = (value, setValue) => {
  //   if (value != 0) {
  //     setValue(String(value).split("R$")[1]?.replaceAll(".", "").split(",")[0]);
  //   }
  // };

  const getUpdate = () => {
    if (id) {
      if (getInfo) {
        console.log(getInfo);
        setVehicle(getInfo.vehicle);
        setKmMax(getInfo.km_max);
        setOutput(getInfo.output);
        setKmChargedUpMaximum(getInfo.km_loaded_above_maximum);
        setKmLoadedAboveMaximum(getInfo.km_loaded_above_maximum);
        setKmEmpty(getInfo.km_empty);
        setCapPeso(getInfo.cap_peso);
        setCubage(parseFloat(getInfo.cubage));
        setAxes(getInfo.axes);
        setVehicleType(getInfo.vehicle_type);
        setAverageVehicleSpeed(getInfo.profit_margin_above_maximum_km);
      }
    }
  };

  // const getCreate = () => {
  //   const dataInput = {
  //     vehicle,
  //     km_max: kmMax,
  //     output,
  //     km_charged_up_maximum: kmChargedUpMaximum,
  //     km_loaded_above_maximum: kmLoadedAboveMaximum,
  //     km_empty: kmEmpty,
  //     cap_peso: capPeso,
  //     cubage,
  //     axes,
  //     vehicle_type: vehicleType,
  //     average_vehicle_speed: averageVehicleSpeed,
  //   };

  //   const length = numberIndex[numberIndex.length - 1] - 1;

  //   if (data.length === 0) {
  //     console.log(dataInput);
  //     setData([dataInput]);
  //   } else {
  //     const newData = [...data];
  //     newData[length] = dataInput;
  //     setData(newData);
  //   }
  // };

  // const getUpdateChange = () => {
  //   if (getInfo) {
  //     getInfo.origin_trade_route =
  //       originTradeRoute || getInfo.origin_trade_route;
  //     getInfo.uf = uf || getInfo.uf;
  //     getInfo.destination_trade_route =
  //       destinationTradeRoute || getInfo.destination_trade_route;
  //     getInfo.transit_time = transitTime || getInfo.transit_time;
  //     getInfo.up_to_10kg =
  //       String(upTo10kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
  //       getInfo.up_to_10kg;
  //     getInfo.up_to_20kg =
  //       String(upTo20kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
  //       getInfo.up_to_20kg;
  //     getInfo.up_to_30kg =
  //       String(upTo30kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
  //       getInfo.up_to_30kg;
  //     getInfo.up_to_50kg =
  //       String(upTo50kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
  //       getInfo.up_to_50kg;
  //     getInfo.up_to_70kg =
  //       String(upTo70kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
  //       getInfo.up_to_70kg;
  //     getInfo.up_to_100kg =
  //       String(upTo100kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
  //       getInfo.up_to_100kg;
  //     getInfo.up_to_150kg = upTo150kg || getInfo.up_to_150kg;
  //     getInfo.up_to_200kg = upTo200kg || getInfo.up_to_200kg;
  //     getInfo.up_to_250kg = upTo250kg || getInfo.up_to_250kg;
  //     getInfo.excess_kg =
  //       String(excessKg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
  //       getInfo.excess_kg;
  //     getInfo.cte_dispatch = cteDispatch || getInfo.cte_dispatch;
  //     getInfo.sefaz_tax = sefazTax || getInfo.sefaz_tax;
  //     getInfo.shipping_value = shippingValue || getInfo.shipping_value;
  //     getInfo.gris = gris || getInfo.gris;
  //     getInfo.icms = icms || getInfo.icms;
  //     getInfo.toll =
  //       String(toll).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
  //       getInfo.toll;
  //   }
  // };

  const getInfoInput = () => {
    setVehicle(data[index]?.vehicle);
    setKmMax(data[index]?.km_max);
    setOutput(data[index]?.output);
    setKmChargedUpMaximum(data[index]?.km_loaded_above_maximum);
    setKmLoadedAboveMaximum(data[index]?.weight_capacity);
    setKmEmpty(data[index]?.cubage);
    setCapPeso(data[index]?.axes);
    setCubage(data[index]?.vehicle_type);
    setAxes(data[index]?.axes);
    setVehicleType(data[index]?.profit_margin_output);
    setAverageVehicleSpeed(data[index]?.profit_margin_above_maximum_km);
    setAverageQualp(data[index]?.profit_margin_which_qualp);
    setGris(data[index]?.gris);
    setAdvalorem(data[index]?.ad_valorem);
  };

  useEffect(() => {
    getInfoInput();
  }, [data]);

  useEffect(() => {
    getUpdate();
  }, [getInfo]);

  // useEffect(() => {
  //   if (!id) {
  //     getCreate();
  //   } else {
  //     getUpdateChange();
  //   }
  // }, [
  //   originTradeRoute,
  //   uf,
  //   destinationTradeRoute,
  //   transitTime,
  //   upTo10kg,
  //   upTo20kg,
  //   upTo30kg,
  //   upTo50kg,
  //   upTo70kg,
  //   upTo100kg,
  //   upTo150kg,
  //   upTo200kg,
  //   upTo250kg,
  //   excessKg,
  //   cteDispatch,
  //   sefazTax,
  //   shippingValue,
  //   gris,
  //   icms,
  //   toll,
  // ]);

  const boxInput = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    width: "100%",
    marginBottom: "10px",
  };

  return (
    <div style={boxInput}>
      <Input
        value={vehicle}
        onChange={(e) => setVehicle(e.target.value)}
        name="Veículo:"
        width="128px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={kmMax}
        onChange={(e) => setKmMax(e.target.value)}
        name="KM Maximo:"
        width="128px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={output}
        onChange={(e) => setOutput(e.target.value)}
        name="Saída:"
        width="100px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={kmChargedUpMaximum}
        onChange={(e) => setKmChargedUpMaximum(e.target.value)}
        name="KM carregado - Acima o km maximo:"
        width="248px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={kmLoadedAboveMaximum}
        onChange={(e) => setKmLoadedAboveMaximum(e.target.value)}
        name="Capacidade de peso:"
        width="148px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={cubage}
        onChange={(e) => setCubage(e.target.value)}
        name="Cubagem:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={axes}
        onChange={(e) => setAxes(e.target.value)}
        name="Eixos:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={cubage}
        onChange={(e) => setCubage(e.target.value)}
        name="Tipo de veiculos:"
        width="130px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={axes}
        onChange={(e) => setAxes(e.target.value)}
        name="Calculo pelo qualp:"
        width="150px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={vehicleType}
        onChange={(e) => setVehicleType(e.target.value)}
        name="Margem de lucro saida"
        width="160px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={averageVehicleSpeed}
        onChange={(e) => setAverageVehicleSpeed(e.target.value)}
        name="Margem de lucro acima do km maximo"
        width="280px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />

      <Input
        value={averageQualp}
        onChange={(e) => setAverageVehicleSpeed(e.target.value)}
        name="Margem de lucro | Qualp"
        width="280px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />

      <Input
        value={gris}
        onChange={(e) => setAverageVehicleSpeed(e.target.value)}
        name="Gris"
        width="280px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />

      <Input
        value={advalorem}
        onChange={(e) => setAverageVehicleSpeed(e.target.value)}
        name="Adi valorem"
        width="280px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
    </div>
  );
};

export default InputsDedicated;
