import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ModalContext } from "../context/Modal";
import { UserContext } from "../context/User";
import app from "../firebase/database";
import uploadUserIcon from "../images/svg/uploadUserIcon.svg";
import api from "../services/api";
import CardTableUsers from "./CardTableUsers";
import Input from "./Input";
import InputSelectFilterManager from "./InputSelectFilterManager";

import axios from "axios";
import { cnpj } from "cpf-cnpj-validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputPrimeReact from "./InputPrimeReact";

const TableListClients = ({ typeAcess }) => {
  const [stateCheckPrimary, setStateCheckPrimary] = useState(false);
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [imageFirebase, setImageFirebase] = useState();

  /* MODAL STATES */

  const { modalGlobal, setStateModalGlobal } = useContext(ModalContext);
  const { data } = useContext(UserContext);

  const [modalActions, setModalActions] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalBlocked, setModalBlocked] = useState(false);

  const [users, setUsers] = useState([]);

  const [name, setName] = useState();
  const [whatsapp, setWhatsapp] = useState();
  const [mail, setMail] = useState();
  const [password, setPassword] = useState();

  const [permission, setPermission] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [documentType, setDocumentType] = useState("99.999.999/9999-99:CNPJ");
  const [cnpjValue, setCnpjValue] = useState("");
  const [cnaes, setCnaes] = useState("");

  const [type_buniness, setType_buniness] = useState();
  const [typeBuniness, setTypeBuniness] = useState("");
  const [status, setStatus] = useState("");

  const [limitMonthly, setLimitMonthly] = useState(0);
  const [whoCreated, setWhoCreated] = useState("");

  /* ERROR MESSAGES */

  const [nameError, setNameError] = useState();
  const [mailError, setMailError] = useState();
  const [passwordError, setPasswordError] = useState();

  const [permissionError, setPermissionError] = useState();

  /* ID */
  const [id, setId] = useState();

  const styleColum1 = {
    maxWidth: "1079px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    background: "white",
  };
  const btnStyleModal = {
    border: "none",
    outline: "none",
    background: "#123164",
    borderRadius: "50px",
    padding: "10px 20px",
  };
  const btnStyleRemove = {
    width: "82px",
    height: "37px",
    borderRadius: "50px",
    border: "none",
    background: "#FFE1E1",
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const loadImage = (file) => {
    setImage(file);
    setImageFirebase(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      setImagePreview(reader.result);
    };
  };

  const closeModal = (value) => {
    setName();
    setWhatsapp();
    setMail();
    setPassword();
    setPermission();
    setImagePreview();
    setCnpjValue();
    setCorporateName();
    setCnaes();
    setLimitMonthly();

    setNameError();
    setMailError();
    setPasswordError();
    setPermissionError();

    setStateModalGlobal(value);
  };

  const closeModalEdit = () => {
    setName();
    setMail();
    setPassword();
    setPermission();
    setImagePreview();
    setLimitMonthly();

    setNameError();
    setMailError();
    setPasswordError();
    setPermissionError();
    setModalEdit(false);
  };

  const getUsers = async () => {
    try {
      const response = await api.get("/user");
      setUsers(response.data);
    } catch (error) {
      setUsers([]);
    }
  };

  const storage = async () => {
    try {
      const payload = {
        name: name,
        corporate_name: corporateName,
        cnpj: cnpjValue,
        cnaes: cnaes,
        type_business: typeBuniness,
        email: mail,
        password: password,
        password_hash: password,
        whatsapp: whatsapp,
        limits_monthly: limitMonthly,
      };
      await api.post("/user/create/admin", payload);
      toast.success("Usuário criado com sucesso", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      getUsers();
      closeModal(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const update = async () => {
    try {
      if (image != undefined) {
        toast.success("alterando cliente....", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        app
          .storage()
          .ref(`/users/${image.name}`)
          .put(image)
          .then(() => {
            app
              .storage()
              .ref(`/users/${image.name}`)
              .getDownloadURL()
              .then(async (url) => {
                await api.patch(`user/${id}`, {
                  picture: url,
                  name: name,
                  mail: mail,
                  corporate_name: permission,
                  cnaes: cnaes,
                  cnpj: cnpjValue,
                  type_buniness: typeBuniness,
                  email: mail,
                });

                toast.success("Cliente alterado com sucesso!", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });

                getUsers();
                closeModalEdit(false);
              });
          });

        return false;
      }

      const response = await api.patch(`user/${id}`, {
        name: name,
        mail: mail,
        corporate_name: permission,
        cnaes: cnaes,
        cnpj: cnpjValue,
        type_buniness: typeBuniness,
        limits_monthly: limitMonthly,
        email: mail,
      });

      if (password) {
        await api.patch(`user/${id}`, {
          password: password,
          password_hash: password,
        });
        getUsers();
        closeModalEdit(false);
      }

      if (response.status == 200) {
        toast.success("Cliente alterado com sucesso!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        getUsers();
        closeModalEdit(false);
      }
    } catch (error) {
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getModalActions = async (id, name, statusUser) => {
    setId(id);
    setModalActions(true);
    setName(name);
    setStatus(statusUser);
  };

  const getModalConfirmation = () => {
    setModalActions(false);
    setModalConfirmation(true);
  };

  const getModaBlocked = () => {
    setModalActions(false);
    setModalBlocked(true);
  };

  const remove = async (mail) => {
    try {
      await api.delete(`/user/${id}`);
      getUsers();
      setModalConfirmation(false);
    } catch (error) {}
  };

  const blocked = async () => {
    try {
      await api.patch(`/block/user/${id}`);
      getUsers();
      setModalBlocked(false);
    } catch (error) {}
  };

  const edit = async () => {
    try {
      setModalActions(false);
      setModalEdit(true);

      const { data } = await api.get(`user/${id}`);

      // Desestruturando os dados para facilitar a atribuição
      const {
        name,
        email,
        corporate_name: permission,
        picture: imagePreview,
        type_buniness: typeBusiness,
        cnaes,
        cnpj,
        limits_monthly: limitMonthly,
        who_created: whoCreated,
      } = data || {};

      setName(name);
      setMail(email);
      setPermission(permission);
      setImagePreview(imagePreview);
      setTypeBuniness(typeBusiness);
      setCnaes(cnaes);
      setCnpjValue(cnpj);
      setLimitMonthly(limitMonthly);
      setWhoCreated(whoCreated);
    } catch (error) {
      // Tratar erros aqui, por exemplo:
      console.error("Failed to fetch user data:", error);
      // Você pode adicionar um alerta ou outra ação apropriada
    }
  };

  const verifyCnpj = async () => {
    const verifyLocation = documentType.split(":")[1] === "CNPJ";
    const validate = cnpj.isValid(cnpjValue);
    const cpfUnformatted = cnpj.strip(cnpjValue);

    if (!validate && verifyLocation) {
      return toast.error("CNPJ inválido", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (verifyLocation) {
      const cnaesVerify = await axios.get(
        `https://brasilapi.com.br/api/cnpj/v1/${cpfUnformatted}`
      );

      const nameCompany = cnaesVerify.data.razao_social;
      const number = cnaesVerify.data.cnae_fiscal;
      const description = cnaesVerify.data.cnae_fiscal_descricao;

      setCorporateName(nameCompany);
      setCnaes(`${number} - ${description}`);
    } else {
      setCorporateName("");
      setCnaes("");
    }
  };

  useEffect(() => {
    if (name != undefined) {
      setNameError();
    }
    if (mail != undefined) {
      setMailError();
    }
    if (password != undefined) {
      setPasswordError();
    }
    if (permission != undefined) {
      setPermissionError();
    }

    if (mail != undefined) {
      const mailIsValid = validateEmail(mail);

      if (mailIsValid == false) {
        setMailError("email inválido");
      }
    }

    if (String(password).length <= 7) {
      setPasswordError("senha deve conter no minimo 8 caracteres");
    }
  }, [name, mail, password, permission]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (String(data).length > 0) {
      setUsers(data);
    }
  }, [data]);

  return (
    <div className="containerBox" style={{ height: "100vh" }}>
      <ToastContainer />

      <div style={styleColum1}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "55px",
            padding: "0 20px",
          }}
        >
          <div style={{ width: "20%" }}>
            <p className="textNameTableListUserTwo">Empresa</p>
          </div>

          <div style={{ width: "18%", textAlign: "center" }}>
            <p className="textNameTableListUserTwo">E-mail</p>
          </div>

          <div style={{ width: "20%" }}>
            <p className="textNameTableListUserTwo">Status</p>
          </div>

          <div style={{ width: "20%" }}>
            <p className="textNameTableListUserTwo">CNPJ</p>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: "20px",
          marginTop: "20px",
          overflowY: "auto",
          height: "calc(100vh - 350px)",
          paddingRight: "20px",
        }}
      >
        {users?.map((item) => {
          return (
            <CardTableUsers
              onClick={() => getModalActions(item.id, item.name, item.status)}
              picture={item.picture}
              name={item.corporate_name}
              mail={item.email}
              level={item.cnpj}
              status={item.status}
              checkNotVisible={stateCheckPrimary}
            />
          );
        })}
      </div>
      {/* CRIAÇÃO */}
      <Modal
        dialogClassName="modal-filter-table-list-user"
        show={modalGlobal}
        onHide={() => closeModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div style={{ display: "flex", height: "30px" }}>
            <div
              onClick={() => closeModal(false)}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_0_2887)">
                  <path
                    d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_2887">
                    <rect
                      width="12.72"
                      height="12.7354"
                      fill="white"
                      transform="translate(0.0143433)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <p className="textPrimaryModalFilterManager">Novo usuário</p>
          </div>

          <button
            className="textBtnFilterManagerModal"
            onClick={() => storage()}
            style={btnStyleModal}
          >
            Criar
          </button>
        </Modal.Header>
        <Modal.Body
          style={{ overflowY: "scroll", maxHeight: 450, padding: "0 25px" }}
        >
          <input
            onChange={(e) => loadImage(e.target.files[0])}
            accept="image/png"
            name="file"
            id="file"
            type="file"
            style={{ display: "none" }}
          />

          {imagePreview != undefined ? (
            <label
              htmlFor="file"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "3.93rem",
                marginTop: "2.12rem",
              }}
            >
              <img
                src={"data:image/;base64;" + imagePreview}
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "100%",
                }}
              />
            </label>
          ) : (
            <label
              htmlFor="file"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "3.93rem",
              }}
            >
              <img src={uploadUserIcon} />
            </label>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <InputSelectFilterManager
              onChange={(e) => setDocumentType(e.target.value)}
              color="var(--grey-1)"
              name="Tipo de documento"
              width={"300px"}
              top="50px"
              custom="false"
              type={"documentType"}
            />

            <Input
              value={cnpjValue}
              mask={documentType.split(":")[0]}
              name={documentType.split(":")[1]}
              width="300px"
              onBlur={verifyCnpj}
              onChange={(e) => setCnpjValue(e.target.value)}
              placeholder={`Insira o seu ${documentType.split(":")[1]}`}
              type={"text"}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Input
              value={corporateName}
              name="Razão social"
              width="300px"
              type={"text"}
              onChange={(e) => setCorporateName(e.target.value)}
            />

            <Input
              value={cnaes}
              name="CNAEs"
              width="300px"
              type={"text"}
              onChange={(e) => setCnaes(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <InputSelectFilterManager
              onChange={(e) => setTypeBuniness(e.target.value)}
              color="var(--grey-1)"
              name="Insira seu tipo de negocio"
              width={"300px"}
              top="50px"
              custom="false"
              type={"register"}
            />

            <div style={{ position: "relative" }}>
              <Input
                onChange={(e) => setName(e.target.value)}
                color="black"
                width="300px"
                height="45px"
                name="Nome do usuário"
                placeholder={`Insira o nome do usuário`}
              />
              <p className="errorMessage">
                {nameError != undefined ? nameError : <></>}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Input
              mask={"(99) 99999-9999"}
              name="Whatsapp"
              width="300px"
              onChange={(e) => setWhatsapp(e.target.value)}
              placeholder="Insira um número"
              type={"text"}
            />

            <div style={{ position: "relative" }}>
              <Input
                onChange={(e) => setMail(e.target.value)}
                color="black"
                width="300px"
                height="45px"
                name="E-mail"
                placeholder={`Insira o e-mail do usuário`}
              />
              <p className="errorMessage">
                {mailError != undefined ? mailError : <></>}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ position: "relative" }}>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                topEye="46px"
                rightEye="10px"
                color="black"
                width="300px"
                height="45px"
                name="Senha"
                placeholder={`Insira uma senha`}
              />
              <p className="errorMessage">
                {passwordError != undefined ? passwordError : <></>}
              </p>
            </div>

            <InputPrimeReact
              label={"Limite de propostas mensal"}
              value={limitMonthly}
              onChange={(e) => setLimitMonthly(e.value)}
              type={"currency"}
              width={"300px"}
              className={"currencyTable"}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* OPÇOES DE ESCOLHA */}
      <Modal
        dialogClassName="modal-filter-table-list-user-edit"
        show={modalActions}
        onHide={() => setModalActions(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            style={{
              display: "flex",
              height: "30px",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              className="textPrimaryModalFilterManager"
              style={{ position: "relative", right: "17px" }}
            >
              Ações
            </p>

            <div
              onClick={() => setModalActions(false)}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_0_2887)">
                  <path
                    d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_2887">
                    <rect
                      width="12.72"
                      height="12.7354"
                      fill="white"
                      transform="translate(0.0143433)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "scroll", maxHeight: 450 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => edit()}
              style={{
                height: "55px",
                width: "100%",
                borderBottom: "1px solid #D7D7D7",
                textAlign: "center",
                paddingTop: "0.9rem",
                cursor: "pointer",
              }}
            >
              <p className="textModalTableListUserEditPrimary">Editar acesso</p>
            </div>

            <div
              onClick={() => getModaBlocked()}
              style={{
                height: "55px",
                width: "100%",
                borderBottom: "1px solid #D7D7D7",
                textAlign: "center",
                paddingTop: "0.9rem",
                cursor: "pointer",
              }}
            >
              <p className="textModalTableListUserEditPrimary">
                {status === "blocked"
                  ? "Desbloquear acesso"
                  : "Bloquear acesso"}
              </p>
            </div>

            <div
              onClick={() => getModalConfirmation()}
              style={{
                height: "55px",
                width: "100%",
                borderBottom: "1px solid #D7D7D7",
                textAlign: "center",
                paddingTop: "0.9rem",
                cursor: "pointer",
              }}
            >
              <p className="textModalTableListUserEditSecondary">
                Excluir acesso
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDITAR */}
      <Modal
        dialogClassName="modal-filter-table-list-user"
        show={modalEdit}
        onHide={() => closeModalEdit(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div style={{ display: "flex", height: "30px" }}>
            <div
              onClick={() => closeModalEdit(false)}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_0_2887)">
                  <path
                    d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_2887">
                    <rect
                      width="12.72"
                      height="12.7354"
                      fill="white"
                      transform="translate(0.0143433)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <p className="textPrimaryModalFilterManager">Editar usuário</p>
          </div>

          <button
            className="textBtnFilterManagerModal"
            onClick={() => update()}
            style={btnStyleModal}
          >
            Salvar alterações
          </button>
        </Modal.Header>
        <Modal.Body
          style={{ overflowY: "scroll", maxHeight: 450, padding: "25px" }}
        >
          <input
            onChange={(e) => loadImage(e.target.files[0])}
            accept="image/png"
            name="file"
            id="file"
            type="file"
            style={{ display: "none" }}
          />

          {imagePreview != undefined ? (
            <label
              htmlFor="file"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "3.93rem",
                marginTop: "2.12rem",
              }}
            >
              <img
                src={imagePreview}
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "100%",
                }}
              />
            </label>
          ) : (
            <label
              htmlFor="file"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "3.93rem",
              }}
            >
              <img src={uploadUserIcon} />
            </label>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ position: "relative" }}>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                color="black"
                width="300px"
                height="45px"
                name="Nome do usuário"
              />
              <p className="errorMessage">
                {nameError != undefined ? nameError : <></>}
              </p>
            </div>

            <div style={{ position: "relative" }}>
              <Input
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                color="black"
                width="300px"
                height="45px"
                name="E-mail"
              />
              <p className="errorMessage">
                {mailError != undefined ? mailError : <></>}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Input
              value={permission}
              onChange={(e) => setPermission(e.target.value)}
              color="black"
              width="300px"
              height="45px"
              name="Nome da empresa"
            />

            <div style={{ position: "relative" }}>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                topEye="46px"
                rightEye="10px"
                color="black"
                width="300px"
                height="45px"
                name="Senha"
              />
              <p className="errorMessage">
                {passwordError != undefined ? passwordError : <></>}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Input
              value={cnaes}
              onChange={(e) => setCnaes(e.target.value)}
              color="black"
              width="300px"
              height="45px"
              name="CNAE"
            />

            <Input
              value={cnpjValue}
              onChange={(e) => setCnpjValue(e.target.value)}
              color="black"
              width="300px"
              height="45px"
              name="CNPJ"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Input
              value={typeBuniness}
              onChange={(e) => setTypeBuniness(e.target.value)}
              color="black"
              width="300px"
              height="45px"
              name="Tipo da empresa"
            />

            {/* <Input
              value={limitMonthly}
              onChange={(e) => setLimitMonthly(e.target.value)}
              color="black"
              width="300px"
              height="45px"
              type="number"
              name="Limite de propostas mensal"
            /> */}

            <InputPrimeReact
              label={"Limite de propostas mensal"}
              value={limitMonthly}
              onChange={(e) => setLimitMonthly(e.value)}
              type={"currency"}
              width={"300px"}
              className={"currencyTable"}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Input
              value={whoCreated}
              color="black"
              width="640px"
              height="45px"
              name="Responsável"
              disabled
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* CONFIRMAR EXCLUSÃO */}
      <Modal
        dialogClassName="modal-filter-table-list-user-edit"
        show={modalConfirmation}
        onHide={() => setModalConfirmation(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            style={{
              display: "flex",
              height: "30px",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => setModalConfirmation(false)}
                style={{ cursor: "pointer" }}
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_0_2887)">
                    <path
                      d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_0_2887">
                      <rect
                        width="12.72"
                        height="12.7354"
                        fill="white"
                        transform="translate(0.0143433)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <p
                className="textPrimaryModalFilterManager"
                style={{ left: "4px", top: "10px" }}
              >
                Excluir usuário
              </p>
            </div>

            <button
              className="textBtnRemove"
              onClick={() => remove()}
              style={btnStyleRemove}
            >
              Excluir
            </button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: "17px" }}>
          <p className="textConfirmationRemove">
            Tem certeza que deseja excluir o usuário <b>{name}</b> ?
          </p>
          <div style={{ height: "40px" }}></div>
        </Modal.Body>
      </Modal>

      {/* CONFIRMAR BLOQUEIO */}
      <Modal
        dialogClassName="modal-filter-table-list-user-edit"
        show={modalBlocked}
        onHide={() => setModalBlocked(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            style={{
              display: "flex",
              height: "30px",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => setModalBlocked(false)}
                style={{ cursor: "pointer" }}
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_0_2887)">
                    <path
                      d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_0_2887">
                      <rect
                        width="12.72"
                        height="12.7354"
                        fill="white"
                        transform="translate(0.0143433)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <p
                className="textPrimaryModalFilterManager"
                style={{ left: "4px", top: "10px" }}
              >
                {status === "blocked"
                  ? "Desbloquear usuário"
                  : "Bloquear usuário"}
              </p>
            </div>

            <button
              className="textBtnRemove"
              onClick={() => blocked()}
              style={{ ...btnStyleRemove, width: "120px" }}
            >
              {status === "blocked" ? "Desbloquear" : "Bloquear"}
            </button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: "17px" }}>
          <p className="textConfirmationRemove">
            Tem certeza que deseja{" "}
            {status === "blocked" ? "desbloquear" : "bloquear"} o usuário{" "}
            <b>{name}</b> ?
          </p>
          <div style={{ height: "40px" }}></div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TableListClients;
