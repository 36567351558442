import React, { useEffect } from "react";
import SelectPrimeComponent from "./SelectPrimeComponent";
import InputPrimeReact from "./InputPrimeReact";

const CollectionFtl = ({
  typeDestiny,

  optionsVehicles,
  onChangeVehicles,
  selectedVehicles,

  // Select de estados
  optionsStates,
  onChangeStates,
  valueState,
  disabledState,

  // Select de cidades
  optionsCitys,
  onChangeCitys,
  valueCity,
}) => {
  // Estilos centralizados
  const styles = {
    container: {
      margin: "20px 0",
      padding: "20px 0",
      borderBottom: "1px solid #d7d7d7",
      borderTop: "1px solid #d7d7d7",
    },
    title: {
      marginBottom: 20,
      fontWeight: 500,
      fontSize: 17,
    },
    flexContainer: {
      display: "flex",
      gap: 20,
    },
    label: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
      fontSize: 15,
    },
    input: {
      padding: "10px 12px",
      borderRadius: 8,
      border: "1px solid #d7d7d7",
      outline: "none",
      width: 250,
    },
    wideInput: {
      width: 500,
    },
  };

  return (
    <div style={styles.container}>
      <p style={styles.title}>{typeDestiny}</p>
      <div style={styles.flexContainer}>
        <SelectPrimeComponent
          label={"Tipos de veiculos"}
          options={optionsVehicles}
          onChange={onChangeVehicles}
          selectedValue={selectedVehicles}
        />

        <InputPrimeReact
          label="Selecionar estado"
          options={optionsStates}
          onChange={onChangeStates}
          value={valueState}
          optionLabel="nome"
          optionValue="sigla"
          type={"dropdownInput"}
          width={"322px"}
          disabled={disabledState}
          idStyle={"ftladdress"}
        />

        <InputPrimeReact
          label="Selecionar cidade"
          options={optionsCitys}
          onChange={onChangeCitys}
          value={valueCity}
          optionLabel="nome"
          type={"dropdownInput"}
          width={"322px"}
          idStyle={"ftladdress"}
        />
      </div>
    </div>
  );
};

export default CollectionFtl;
