import React from "react";

const DeliverOrPickup = ({
  hasCollection,
  storage,
  storageAddress,
  city,
  setCity,
  address,
  setAddress,
  typeDestiny,
}) => {
  // Estilos centralizados
  const styles = {
    container: {
      marginTop: 20,
      paddingBottom: 20,
      marginBottom: 20,
      borderBottom: "1px solid #d7d7d7",
    },
    title: {
      marginBottom: 20,
      fontWeight: 500,
      fontSize: 17,
    },
    flexContainer: {
      display: "flex",
      gap: 20,
    },
    label: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
      fontSize: 15,
    },
    input: {
      padding: "10px 12px",
      borderRadius: 8,
      border: "1px solid #d7d7d7",
      outline: "none",
      width: 250,
    },
    wideInput: {
      width: 500,
    },
  };

  return (
    <div style={styles.container}>
      <p style={styles.title}>{typeDestiny}</p>
      <div style={styles.flexContainer}>
        {hasCollection ? (
          <>
            <label style={styles.label}>
              Cidade para Entrega:
              <input
                type="text"
                value={city}
                style={styles.input}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Digite a cidade"
              />
            </label>
            <label style={styles.label}>
              Endereço - Opcional:
              <input
                type="text"
                value={address}
                style={{ ...styles.input, ...styles.wideInput }}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Digite o endereço"
              />
            </label>
          </>
        ) : (
          <label style={styles.label}>
            Endereço do Armazém:
            <input
              type="text"
              value={storageAddress}
              disabled
              style={{ ...styles.input, ...styles.wideInput }}
              placeholder="Digite o endereço do armazém"
              required
            />
          </label>
        )}
      </div>
    </div>
  );
};

export default DeliverOrPickup;
