import React, { useContext, useEffect, useState } from "react";
import closeIcon from "../images/svg/closeIcon.svg";
import downloadIcon from "../images/svg/downloadIcon.svg";
import Button from "./Button";
import LetterLimit from "../utils/LimitCharacters";
import ModalContainer from "./ModalContainer";
import CardDiscount from "./CardDiscount";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import ModalProposalRefuse from "./ModalProposalRefuse";
import CardAdditional from "./CardAdditional";

const ModalProposal = ({
  setModal,
  proposal,
  closeModal,
  client,
  viewed,
  checkStatusForClass,
}) => {
  const {
    approvalProposal,
    refuseProposal,
    approvalProposalClient,
    refuseProposalClient,
    viewedProposalClient,
    newProposalCounter,
    countProposalUnviewed,
    approvalWithConditionsProposal,
  } = useContext(ProposalContext);
  const [modalDiscont, setModalDiscont] = useState(false);
  const [modalAdditional, setModalAdditional] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const [modalRefuse, setModalRefuse] = useState(false);
  const columnContent = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  };

  const containerDetailsHeader = {
    display: "flex",
    gap: "20px",
    alignItems: "start",
  };

  const container = {
    border: "1px solid transparent",
    borderRadius: "8px",
    backgroundColor: "#fff",
    width: "800px",
    height: "600px",
    position: "relative",
  };

  const header = {
    padding: "20px 30px",
    borderBottom: "1px solid #d7d7d7",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  };

  const containerHeader = {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #d7d7d7",
    alignContent: "center",
    paddingBottom: "10px",
    position: "relative",
  };

  const headerProposal = {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  };

  const pdfContainer = {
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    marginTop: "10px",
  };

  const pdfContainerHeader = {
    height: "54px",
    borderBottom: "1px solid #d7d7d7",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 10px 0 20px",
  };

  const pdf = {
    width: "100%",
    minHeight: "900px",
    padding: "20px",
  };

  const footer = {
    position: "absolute",
    bottom: "0",
    width: "100%",
    height: "83px",
    backgroundColor: "#FFFFFF",
    borderTop: "1px solid #d7d7d7",
    borderRadius: "0 0 10px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    padding: "0 20px",
  };

  const handleDownloadPdf = () => {
    window.open(pdfUrl, "_blank");
  };

  const refuseProposals = () => {
    if (client) setModalRefuse(true);
    else refuseProposal(proposal.id, closeModal);
  };

  const approveOrResubmit = () => {
    if (client) return "Aprovar proposta";
    else if (proposal?.approved_by_client?.includes("Recusado"))
      return "Reenviar Proposta";
    else return "Aprovar proposta";
  };

  useEffect(() => {
    setPdfUrl(proposal?.proposal_pdf);
  }, [proposal]);

  useEffect(() => {
    if (!viewed && client) viewedProposalClient(proposal.id);
  }, [viewed]);

  useEffect(() => {
    setTimeout(() => {
      if (countProposalUnviewed > 0) newProposalCounter();
    }, 1000);
  }, []);

  return (
    <>
      <div style={container}>
        <div style={header}>
          <img src={closeIcon} style={{ width: "13px" }} onClick={closeModal} />
          <p className="headerProposal">Detalhes da Proposta</p>
        </div>
        <div style={{ padding: "20px", height: "480px", overflowY: "auto" }}>
          <div style={{ padding: "20px" }}>
            <div
              style={{
                width: "280px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              <p className={checkStatusForClass(proposal.status)}>
                {proposal.status}
              </p>
            </div>
            <div style={containerHeader}>
              <div style={headerProposal}>
                <p className="headerProposal">Proposta {proposal.type}</p>
              </div>
              {!client && (
                <>
                  <Button
                    padding={"5px 20px"}
                    background={modalAdditional ? "#FFE1E1" : "#123164"}
                    borderRadius="10px"
                    marginTop="0"
                    name={modalAdditional ? "Fechar" : "Aplicar adicionais"}
                    color={modalAdditional ? "#EA0000" : "#fff"}
                    onClick={() => setModalAdditional(!modalAdditional)}
                    border="1px solid #FFE1E1"
                  />
                  <ModalContainer
                    closeModal={() => setModalAdditional(false)}
                    isActive={modalAdditional}
                    idClass={"modal-additional"}
                  >
                    <CardAdditional
                      isActive={modalAdditional}
                      idProposal={proposal.id}
                      advanceDiscount={parseFloat(proposal.advance_discount)}
                      proposalInfo={proposal.proposal_details}
                      close={() => setModalAdditional(false)}
                      closeAll={() => {
                        setModalAdditional(false);
                      }}
                    />
                  </ModalContainer>
                  <Button
                    padding={"5px 20px"}
                    background={modalDiscont ? "#FFE1E1" : "#123164"}
                    borderRadius="10px"
                    marginTop="0"
                    name={modalDiscont ? "Fechar" : "Aplicar desconto"}
                    color={modalDiscont ? "#EA0000" : "#fff"}
                    onClick={() => setModalDiscont(!modalDiscont)}
                    border="1px solid #FFE1E1"
                  />
                  <CardDiscount
                    isActive={modalDiscont}
                    idProposal={proposal.id}
                    advanceDiscount={parseFloat(proposal.advance_discount)}
                    close={() => setModalDiscont(false)}
                    closeAll={() => {
                      setModalDiscont(false);
                      setModal(false);
                    }}
                  />
                </>
              )}
            </div>

            <div style={{ paddingTop: "10px" }}>
              {/* LINE 1 */}
              <div style={containerDetailsHeader}>
                <div style={{ ...columnContent, width: "130px" }}>
                  <p className="headerDetailsProposal">N° da proposta</p>
                  <p className="contentDetailsProposal">
                    {proposal.proposal_number}
                  </p>
                </div>

                <div style={{ ...columnContent, minWidth: "135px" }}>
                  <p className="headerDetailsProposal">Tipo</p>

                  <LetterLimit
                    text={proposal.type}
                    limit={17}
                    threePoints={true}
                    className="contentDetailsProposal"
                    id="type"
                  />
                </div>

                <div
                  style={{
                    ...columnContent,
                    width: "150px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <p className="headerDetailsProposal">Cliente</p>

                  <LetterLimit
                    text={proposal.company}
                    limit={16}
                    threePoints={true}
                    className="contentDetailsProposal"
                    id="type"
                  />
                </div>

                <div style={columnContent}>
                  <p className="headerDetailsProposal">E-mail</p>

                  <LetterLimit
                    text={proposal.email}
                    limit={20}
                    threePoints={true}
                    className="contentDetailsProposal"
                    id="email"
                  />
                </div>
              </div>

              {/* LINE 2 */}
              <div style={{ ...containerDetailsHeader, marginTop: "10px" }}>
                <div style={{ ...columnContent, minWidth: "130px" }}>
                  <p className="headerDetailsProposal">Valor da proposta</p>
                  <p className="contentDetailsProposal">{proposal.value}</p>
                </div>

                <div style={{ ...columnContent, minWidth: "140px" }}>
                  <p className="headerDetailsProposal">Prazo da proposta</p>
                  <p className="contentDetailsProposal">{proposal.term}</p>
                </div>

                <div style={columnContent}>
                  <p className="headerDetailsProposal">Data e hora</p>
                  <p className="contentDetailsProposal">
                    {proposal.generated_at}
                  </p>
                </div>
              </div>

              {/* LINE 3 */}

              {!client && (
                <div style={{ ...containerDetailsHeader, marginTop: "10px" }}>
                  <div style={{ ...columnContent, minWidth: "130px" }}>
                    <p className="headerDetailsProposal">
                      Data e hora de visualização
                    </p>
                    <p className="contentDetailsProposal">
                      {proposal.viewed_details}
                    </p>
                  </div>

                  {proposal.cancellation && (
                    <div style={{ ...columnContent, minWidth: "130px" }}>
                      <p className="headerDetailsProposal">
                        Motivo de cancelamento
                      </p>
                      <p className="contentDetailsProposal">
                        {proposal.cancellation}
                      </p>
                    </div>
                  )}

                  <div style={{ ...columnContent, minWidth: "130px" }}>
                    <p className="headerDetailsProposal">Número / WhatsApp</p>
                    <p className="contentDetailsProposal">{proposal.phone}</p>
                  </div>
                </div>
              )}

              <div style={pdfContainer}>
                <div style={pdfContainerHeader}>
                  <p>Visualização da proposta</p>
                  <img src={downloadIcon} onClick={handleDownloadPdf} />
                </div>
                <iframe src={proposal.proposal_pdf} style={pdf} />
              </div>
            </div>
          </div>
        </div>

        <div style={footer}>
          <Button
            width="100%"
            height="40px"
            background={"#E0FFF0"}
            borderRadius="60px"
            marginTop="0"
            name={approveOrResubmit()}
            color={"#00B386"}
            onClick={() => {
              client
                ? approvalProposalClient(proposal.id, closeModal)
                : approvalProposal(proposal.id, closeModal);
            }}
            border="1px solid #E0FFF0"
          />

          {!client && (
            <Button
              width="100%"
              height="40px"
              background={"#9d007a50"}
              borderRadius="60px"
              marginTop="0"
              name="Aprovar com condições"
              color={"#9d007a"}
              onClick={() =>
                approvalWithConditionsProposal(proposal.id, closeModal)
              }
              border="1px solid #FFE1E1"
            />
          )}

          {client ? (
            !proposal.status.includes("Recusado") && (
              <Button
                width="100%"
                height="40px"
                background={"#FFE1E1"}
                borderRadius="60px"
                marginTop="0"
                name="Recusar proposta"
                color={"#EA0000"}
                onClick={() => refuseProposals()}
                border="1px solid #FFE1E1"
              />
            )
          ) : (
            <Button
              width="100%"
              height="40px"
              background={"#FFE1E1"}
              borderRadius="60px"
              marginTop="0"
              name="Recusar proposta"
              color={"#EA0000"}
              onClick={() => refuseProposals()}
              border="1px solid #FFE1E1"
            />
          )}
        </div>
      </div>

      <ModalContainer
        isActive={modalRefuse}
        closeModal={() => setModalRefuse(false)}
        zIndex={3}
        idClass={"modalContainerRefuse"}
        opacity={40}
      >
        <ModalProposalRefuse
          proposalId={proposal.id}
          closeModal={() => closeModal()}
        />
      </ModalContainer>
    </>
  );
};
export default ModalProposal;
