import { useContext, useState } from "react";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import Button from "./Button";
import Textarea from "./Textarea";

const ModalProposalRefuse = ({ proposalId, closeModal }) => {
  const { refuseProposalClient } = useContext(ProposalContext);

  const [reasonCancellation, setReasonCancellation] = useState("");
  const [reasonCancellationDetails, setReasonCancellationDetails] =
    useState("");

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "20px 0",
        border: "1px solid #d7d7d7",
        borderRadius: "8px",
        width: "400px",
      }}
    >
      <div
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid #d7d7d7",
          padding: "0 20px 10px",
        }}
      >
        <p style={{ fontWeight: 500 }}>Motivo de cancelamento</p>
      </div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        <p style={{ fontSize: 14 }}>Selecione o motivo:</p>
        <select
          onChange={(e) => setReasonCancellation(e.target.value)}
          style={{
            fontSize: 14,
            outline: "none",
            border: "none",
            borderBottom: "1px solid #d7d7d7",
          }}
        >
          <option value={""}>Selecione</option>
          <option value={"Frete"}>Frete</option>
          <option value={"Prazo"}>Prazo de entrega</option>
          <option value={"Outros"}>Outros</option>
        </select>
      </div>

      <div style={{ padding: "0 20px" }}>
        <Textarea
          height={200}
          placeholder={"Descreva o motivo . . ."}
          onChange={(e) => setReasonCancellationDetails(e.target.value)}
        />
      </div>
      <div style={{ padding: "0 20px" }}>
        <Button
          width="100%"
          background={"#123164"}
          borderRadius="60px"
          marginTop={20}
          name="Enviar"
          color={"#fff"}
          border="1px solid #FFE1E1"
          padding="5px"
          onClick={() =>
            refuseProposalClient(
              proposalId,
              closeModal,
              reasonCancellation,
              reasonCancellationDetails
            )
          }
        />
      </div>
    </div>
  );
};

export default ModalProposalRefuse;
