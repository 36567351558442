import React, { useState, useEffect } from "react";

import ThowColumLayoutLogin from "../../layouts/ThowColumLayoutLogin";
import loginAdmIcon from "../../images/svg/loginAdmIcon.svg";
import companyIcon from "../../images/svg/companyIcon.svg";
import Button from "../../components/Button";
import api from "../../services/api";
import Input from "../../components/Input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const LoginAdm = () => {
  const [mail, setMail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  /* MESSAGE ERROS */

  const [mailError, setMailError] = useState();
  const [paswordError, setPasswordError] = useState();

  /* BTN STATE */

  const [btnState, setBtnState] = useState();

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSubmitSession = async () => {
    if ((!mail, !password)) {
      return toast.error("Preencha os campos de e-mail e senha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (!validateEmail(mail)) {
      return toast.error("Email inválido", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const data = {
      email: mail,
      password: password,
    };

    try {
      const response = await api.post("/session/admin", data);

      const { token, permission } = response.data;
      localStorage.clear();

      localStorage.setItem("@MAXITRANS_TOKEN_ADMIN:", token);

      if (permission !== "adm") {
        navigate("/dashboard/manage/admin", { replace: true });
      } else {
        navigate("/dashboard/admin", { replace: true });
      }
    } catch (error) {
      if (!error?.response?.status) {
        toast.warn(
          `Desculpe, parece que estamos enfrentando problemas técnicos no momento. 
          Por favor, entre em contato com nossa equipe de suporte para obter assistência. 
          Agradecemos sua compreensão.`,
          {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  useEffect(() => {
    // localStorage.clear();
  }, []);

  const container = {
    width: "418px",
  };

  const boxFooterButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const containerImage = {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "110px 52px",
  };

  const image = {
    height: "100%",
  };

  const containerColum2 = {
    paddingTop: "7.5rem",
  };

  return (
    <ThowColumLayoutLogin
      page={"Landing"}
      colum1={
        <div style={containerImage}>
          <img src={loginAdmIcon} style={image} />
        </div>
      }
      colum2={
        <div style={containerColum2}>
          <img src={companyIcon} />

          <div style={container}>
            <h2 className="textPrimaryLoginAdm">Acesso administrativo</h2>

            <Input
              name="E-mail"
              error={mailError}
              onChange={(e) => setMail(e.target.value)}
              onKeyBoard={onSubmitSession}
              placeholder="Insira seu e-mail"
              type={"text"}
            />
            <Input
              name="Senha"
              error={paswordError}
              onChange={(e) => setPassword(e.target.value)}
              onKeyBoard={onSubmitSession}
              placeholder="Insira sua senha"
              type={"password"}
            />

            <div style={boxFooterButton}>
              <Button
                width="185px"
                height="48px"
                background="#123164"
                borderRadius="60px"
                marginTop="0"
                name="Entrar"
                border="none"
                disabled={btnState}
                onClick={() => onSubmitSession()}
              />
            </div>
          </div>
        </div>
      }
    ></ThowColumLayoutLogin>
  );
};

export default LoginAdm;
