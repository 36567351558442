import React, { useState, useEffect, useContext } from "react";
import ThowColumLayout from "../../layouts/ThowColumLayout";
import Sidebar from "../../components/Sidebar";
import HeaderDashboard from "../../components/HeaderDashboard";
import SectionSelectShippingType from "../../components/SectionSelectShippingType";
import SectionSelectQuotationInformation from "../../components/SectionSelectQuotationInformation";
import SectionSelectQuotationSummary from "../../components/SectionSelectQuotationSummary";
import Button from "../../components/Button";
import SectionSelectQuotationSummaryNational from "../../components/SectionSelectQuotationSummaryNational";
import InsertInformation from "../../components/InsertInformation";
import ModalContainer from "../../components/ModalContainer";
import ModalCompleteQuotation from "../../components/ModalCompleteQuotation";
import jwt_decode from "jwt-decode";
import { DashboardContext } from "../../context/Dashboard/DashboardContext";
import api from "../../services/api";
import { toast } from "react-toastify";
import { ProposalContext } from "../../context/Dashboard/ProposalContext";

const Dashboard = () => {
  const {
    getProfileContext,

    /* CHECK FRACTIONAL */
    dataCheckFractional,
    setDataCheckFractional,

    /* CHECK DISTRIBUTION NATIONAL */
    dataStates,
    comments,

    /* CHECK STORAGE */
    dataStorage,

    /* CHECK DEDICATED FREIGHT */
    dataFractionDedicated,

    /* MATIME TYPES */
    typeService,
    typeContainer,
    modelContainer,
    typeVehicle,
    fileStates,
    dataMaritime,
    dataAir,
  } = useContext(DashboardContext);

  const {
    btnStateNationalFractioned,
    setBtnStateNationalFractioned,
    btnStateNationalFractionedTwo,
    setBtnStateNationalFractionedTwo,
    setBtnStateNationalDedicated,
    btnStateNationalDedicated,
    btnStateInternationLTL,
    setBtnStateInternationLTL,
    notifications,
    btnStateInternationFTL,
    setBtnStateInternationFTL,
    newProposalCounter,
  } = useContext(ProposalContext);

  const [modal, setModal] = useState(false);
  const [additionalModal, setAdditionalModal] = useState(false);
  const [requestQuotation, setRequestQuotation] = useState(false);

  /* BUTTONS SECTION */
  const [nationalRoad, setNationalRoad] = useState(true);
  const [internationalRoad, setInternationalRoad] = useState(false);
  const [storage, setStorage] = useState(false);

  const [airTransport, setAirTransport] = useState(false);
  const [maritimeTransport, setMaritimeTransport] = useState(false);

  /* NATIONAL BUS STATION */
  const [checkFractional, setCheckFractional] = useState(true);
  const [checkNational, setCheckNational] = useState(false);
  const [checkDedicated, setCheckDedicated] = useState(false);

  /* INTERNATIONAL BUS STATION */
  const [checkStandardInternational, setCheckStandardInternational] =
    useState(false);
  const [checkPremiumInternational, setCheckPremiumInternational] =
    useState(false);
  const [checkDedicatedInternational, setCheckDedicatedInternational] =
    useState(false);

  /* AIR TRANSPORT */
  const [checkNationalAir, setCheckNationalAir] = useState(false);
  const [checkInternationalAir, setCheckInternationalAir] = useState(false);

  /* STATES LIST CARDS */

  /* NATIONAL BUS STATION */
  const [arrayNationalRoadFractional, setArrayNationalRoadFractional] =
    useState([1]);
  const [arrayNationalRoadDedicated, setArrayNationalRoadDedicated] = useState([
    1,
  ]);

  /* INTERNATIONAL BUS STATION */
  const [arrayInternationalRoadStandard, setArrayInternationalRoadStandard] =
    useState([1]);
  const [arrayInternationalRoadPremium, setArrayInternationalRoadPremium] =
    useState([1]);
  const [arrayInternationalRoadDedicated, setArrayInternationalRoadDedicated] =
    useState([1]);

  /* AIR TRANSPORT */
  const [arrayAirTransportNationalAir, setArrayAirTransportNationalAir] =
    useState([1]);
  const [
    arrayAirTransportInternationalAir,
    setArrayAirTransportInternationalAir,
  ] = useState([1]);

  /* MARITIME TRANSPORT */
  const [arrayMaritimeTransport, setArrayMaritimeTransport] = useState([1]);

  /* ANIMATION TO REMOVE CARD */
  const [removeCard, setRemoveCard] = useState(false);

  /* CARDS REMOVAL FUNCTION */
  const removeCardQuotation = (id, state, setState, index) => {
    const newQuotationInformation = state.filter((item) => item !== id);
    setState(newQuotationInformation);
  };

  /* INPUT VALUES DETAILS */
  const [sumResumeQuotation, setSumResumeQuotation] = useState([]);

  /* BTN DISABLE */
  const [btnDisabled, setBtnDisabled] = useState(false);

  /* Visible Proposal */
  const [visibleProposal, setVisibleProposal] = useState(false);

  /* Check Fractional Function */
  const onSubmitCheckFractional = async () => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
    const decoded = jwt_decode(token);
    const { id } = decoded;
    const response = await getProfileContext(id);
    const loadingToast = notifications("loading", "Calculando proposta...");

    setBtnDisabled(true);

    try {
      await api.post("/discounts/quotation/proposal", {
        email: response.email,
        data: dataCheckFractional,
      });
      setBtnDisabled(false);
      setModal(true);
      toast.dismiss(loadingToast);
      await newProposalCounter();
    } catch (error) {
      setBtnDisabled(false);
      toast.error(error?.response?.data?.error || error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  /* Check Distribution National Function */
  const onSubmitCheckDistributionNational = async () => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
    const decoded = jwt_decode(token);
    const { id } = decoded;
    const response = await getProfileContext(id);
    setBtnDisabled(true);
    const loadingToast = notifications("loading", "Calculando proposta...");

    try {
      await api.post("/discounts/quotation/proposal/national/distribution", {
        email: response.email,
        states: dataStates,
        comments: comments,
        file: fileStates,
      });
      setBtnDisabled(false);
      setModal(true);

      toast.dismiss(loadingToast);

      await newProposalCounter();
    } catch (error) {
      setBtnDisabled(false);
      toast.error(error?.response?.data?.error || error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  /* Check Fractional Function */
  const onSubmitCheckInternationalLTL = async () => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
    const decoded = jwt_decode(token);
    const { id } = decoded;
    const response = await getProfileContext(id);
    const loadingToast = notifications("loading", "Calculando proposta...");

    setBtnDisabled(true);
    try {
      const { data } = await api.post(
        "/discounts/quotation/international/ltl",
        {
          email: response.email,
          data: dataCheckFractional,
        }
      );
      toast.dismiss(loadingToast);
      setBtnDisabled(false);
      setModal(true);
      if (data.message === "Proposta enviada para analize!") {
        setAdditionalModal("Analize");
      }

      await newProposalCounter();
    } catch (error) {
      toast.dismiss(loadingToast);
      setBtnDisabled(false);

      toast.error(error?.response?.data?.error || error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  /* Check Fractional Function */
  const onSubmitCheckInternationalFTL = async () => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
    const decoded = jwt_decode(token);
    const { id } = decoded;
    const response = await getProfileContext(id);
    const loadingToast = notifications("loading", "Calculando proposta...");

    setBtnDisabled(true);

    try {
      const { data } = await api.post(
        "/discounts/quotation/international/ftl",
        {
          email: response.email,
          data: dataCheckFractional,
        }
      );
      toast.dismiss(loadingToast);

      console.log(data.message);

      if (data.message === "Proposta enviada para analize!") {
        setAdditionalModal("Analize");
      }
      setBtnDisabled(false);
      setModal(true);

      await newProposalCounter();
    } catch (error) {
      toast.dismiss(loadingToast);
      setBtnDisabled(false);
      console.log(error?.response?.data);
      toast.error(error?.response?.data?.error || error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  /* Check Storage Function */
  const onSubmitStoragenNational = async () => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
    const decoded = jwt_decode(token);
    const { id } = decoded;
    const response = await getProfileContext(id);
    setBtnDisabled(true);

    try {
      await api.post("/discounts/quotation/proposal/storage", {
        email: response.email,
        data: dataStorage,
      });
      setBtnDisabled(false);
      setModal(true);
      setRequestQuotation(true);
    } catch (error) {
      setBtnDisabled(false);
      toast.error(error?.response?.data?.error || error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  /* Check Dedicated Freight Function */
  const onSubmitCheckDedicatedFreightNational = async () => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
    const decoded = jwt_decode(token);
    const { id } = decoded;
    const response = await getProfileContext(id);
    setBtnDisabled(true);

    if (!dataFractionDedicated[1].freight) {
      setBtnDisabled(false);
      return toast.warn("Preencha os campos de origem e destino corretamente", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    const loadingToast = notifications("loading", "Calculando proposta...");

    try {
      await api.post("/discounts/quotation/proposal/dedicated", {
        email: response.email,
        data: dataFractionDedicated,
        typeVehicle: typeVehicle,
      });
      setBtnDisabled(false);
      setModal(true);
      await newProposalCounter();
      toast.dismiss(loadingToast);
    } catch (error) {
      setBtnDisabled(false);
      toast.error(error?.response?.data?.error || error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      toast.dismiss(loadingToast);
    }
  };

  /* Check Air National Function */
  const onSubmitCheckAirNational = async () => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
    const decoded = jwt_decode(token);
    const { id } = decoded;
    const response = await getProfileContext(id);
    setBtnDisabled(true);

    try {
      await api.post("/discounts/quotation/proposal/air", {
        email: response.email,
        data: dataAir,
      });
      setBtnDisabled(false);
      setModal(true);
      setRequestQuotation(true);
    } catch (error) {
      setBtnDisabled(false);
      toast.error(error?.response?.data?.error || error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const onSubmitCheckMaritimeTransport = async () => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
    const decoded = jwt_decode(token);
    const { id } = decoded;
    const response = await getProfileContext(id);
    setBtnDisabled(true);

    try {
      await api.post("/discounts/quotation/proposal/maritime/box", {
        email: response.email,
        data: dataMaritime,
        typeService: typeService,
        typeContainer: typeContainer,
        modelContainer: modelContainer,
      });
      setBtnDisabled(false);
      setModal(true);
      setRequestQuotation(true);
    } catch (error) {
      setBtnDisabled(false);
      toast.error(error?.response?.data?.error || error.response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleProposal = (check) => {
    setBtnDisabled(true);
    const condictions = {
      checkFractional: () => {
        setBtnStateNationalFractionedTwo(!btnStateNationalFractionedTwo);
        setTimeout(() => {
          setBtnStateNationalFractioned(!btnStateNationalFractioned);
        }, 1000);
        setTimeout(() => {
          onSubmitCheckFractional();
        }, 2500);
      },
      checkDedicated: () => {
        setBtnStateNationalDedicated(!btnStateNationalDedicated);
        setTimeout(() => {
          onSubmitCheckDedicatedFreightNational();
        }, 5000);
      },
      checkInternationalStandard: () => {
        setBtnStateInternationLTL(!btnStateInternationLTL);
        setTimeout(() => {
          onSubmitCheckInternationalLTL();
        }, 6000);
      },
      checkDedicatedInternational: () => {
        setBtnStateInternationFTL(!btnStateInternationFTL);
        setTimeout(() => {
          onSubmitCheckInternationalFTL();
        }, 7000);
      },
    };

    condictions[check]();
  };

  /* STYLES */
  const container = {
    maxWidth: "100%",
    paddingRight: "4.125rem",
    paddingTop: "2.6875rem",
    paddingLeft: "3.375rem",
    backgroundColor: "#F9F9F9",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "20px",
  };

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(showPosition);
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }

  //   function showPosition(position) {
  //     console.log(position?.coords?.latitude, position?.coords?.longitude);
  //   }

  //   showPosition();
  // }, []);

  useEffect(() => {
    newProposalCounter();
  }, []);

  return (
    <ThowColumLayout
      page={"Dashboard"}
      colum1={<Sidebar page="Dashboard" access="client" />}
      colum2={
        <>
          <HeaderDashboard name="Cotação de frete" />

          <div className="boxContainerCard" style={container}>
            <SectionSelectShippingType
              nationalRoad={nationalRoad}
              setNationalRoad={setNationalRoad}
              internationalRoad={internationalRoad}
              setInternationalRoad={setInternationalRoad}
              storage={storage}
              setStorage={setStorage}
              airTransport={airTransport}
              setAirTransport={setAirTransport}
              maritimeTransport={maritimeTransport}
              setMaritimeTransport={setMaritimeTransport}
              checkFractional={checkFractional}
              setCheckFractional={setCheckFractional}
              checkNational={checkNational}
              setCheckNational={setCheckNational}
              checkDedicated={checkDedicated}
              setCheckDedicated={setCheckDedicated}
              checkStandardInternational={checkStandardInternational}
              setCheckStandardInternational={setCheckStandardInternational}
              checkPremiumInternational={checkPremiumInternational}
              setCheckPremiumInternational={setCheckPremiumInternational}
              checkDedicatedInternational={checkDedicatedInternational}
              setCheckDedicatedInternational={setCheckDedicatedInternational}
              checkNationalAir={checkNationalAir}
              setCheckNationalAir={setCheckNationalAir}
              checkInternationalAir={checkInternationalAir}
              setCheckInternationalAir={setCheckInternationalAir}
              setDataCheckFractional={setDataCheckFractional}
            />

            {checkFractional && (
              <>
                {arrayNationalRoadFractional.map((key, index) => {
                  const lastCard =
                    arrayNationalRoadFractional[
                      arrayNationalRoadFractional.length - 1
                    ];

                  return (
                    <SectionSelectQuotationInformation
                      buttonAddCard={key === lastCard}
                      key={key}
                      id={key}
                      sumResume={sumResumeQuotation}
                      setSumResume={setSumResumeQuotation}
                      index={index}
                      type={"checkFractional"}
                      check={"checkFractional"}
                      removeAnimation={removeCard}
                      addCard={() =>
                        setArrayNationalRoadFractional([
                          ...arrayNationalRoadFractional,
                          key + 1,
                        ])
                      }
                      visibleProposal={visibleProposal}
                      removeCard={() => {
                        setRemoveCard(true);
                        setTimeout(() => {
                          setRemoveCard(false);
                          removeCardQuotation(
                            lastCard,
                            arrayNationalRoadFractional,
                            setArrayNationalRoadFractional
                          );
                        }, 580);
                      }}
                      isPrimaryCard={arrayNationalRoadFractional.length == 1}
                    />
                  );
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "1000px",
                    width: "100%",
                  }}
                >
                  <Button
                    width="225px"
                    disabled={btnDisabled}
                    height="45px"
                    background={"var(--color-primary)"}
                    borderRadius="60px"
                    marginTop="0"
                    name="Concluir cotação"
                    color={"var(--white)"}
                    onClick={() => handleProposal("checkFractional")}
                    border="none"
                  />
                </div>
              </>
            )}

            {checkNational && (
              <>
                <SectionSelectQuotationSummaryNational />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "1000px",
                    width: "100%",
                  }}
                >
                  <Button
                    width="225px"
                    disabled={btnDisabled}
                    height="45px"
                    background={"var(--color-primary)"}
                    borderRadius="60px"
                    marginTop="0"
                    name="Concluir cotação"
                    color={"var(--white)"}
                    onClick={() => onSubmitCheckDistributionNational()}
                    border="none"
                  />
                </div>
              </>
            )}

            {checkDedicated && (
              <>
                {arrayNationalRoadDedicated.map((key, index) => {
                  const lastCard =
                    arrayNationalRoadDedicated[
                      arrayNationalRoadDedicated.length - 1
                    ];

                  return (
                    <SectionSelectQuotationInformation
                      check={"checkDedicated"}
                      key={key}
                      id={key}
                      index={index}
                      isPrimaryCard={arrayNationalRoadDedicated.length == 1}
                    />
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "1000px",
                    width: "100%",
                  }}
                >
                  <Button
                    width="225px"
                    disabled={btnDisabled}
                    height="45px"
                    background={"var(--color-primary)"}
                    borderRadius="60px"
                    marginTop="0"
                    name="Concluir cotação"
                    color={"var(--white)"}
                    onClick={() => handleProposal("checkDedicated")}
                    border="none"
                  />
                </div>
              </>
            )}

            {checkStandardInternational && (
              <>
                {arrayInternationalRoadStandard.map((key, index) => {
                  const lastCard =
                    arrayInternationalRoadStandard[
                      arrayInternationalRoadStandard.length - 1
                    ];

                  return (
                    <SectionSelectQuotationInformation
                      check={"checkInternationalStandard"}
                      inconterm={true}
                      imo={true}
                      type={"standard"}
                      servicesAdd={true}
                      // buttonAddCard={key === lastCard}
                      buttonAddCard={false}
                      key={key}
                      id={key}
                      index={index}
                      removeAnimation={removeCard}
                      addCard={() =>
                        setArrayInternationalRoadStandard([
                          ...arrayInternationalRoadStandard,
                          key + 1,
                        ])
                      }
                      removeCard={() => {
                        setRemoveCard(true);
                        setTimeout(() => {
                          setRemoveCard(false);
                          removeCardQuotation(
                            lastCard,
                            arrayInternationalRoadStandard,
                            setArrayInternationalRoadStandard
                          );
                        }, 580);
                      }}
                      isPrimaryCard={arrayInternationalRoadStandard.length == 1}
                    />
                  );
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "1000px",
                    width: "100%",
                  }}
                >
                  <Button
                    width="225px"
                    disabled={btnDisabled}
                    height="45px"
                    background={"var(--color-primary)"}
                    borderRadius="60px"
                    marginTop="0"
                    name="Concluir cotação"
                    color={"var(--white)"}
                    onClick={() => handleProposal("checkInternationalStandard")}
                    border="none"
                  />
                </div>
              </>
            )}

            {checkPremiumInternational && (
              <>
                {arrayInternationalRoadPremium.map((key, index) => {
                  const lastCard =
                    arrayInternationalRoadPremium[
                      arrayInternationalRoadPremium.length - 1
                    ];

                  return (
                    <SectionSelectQuotationInformation
                      check={"checkInternationalPremium"}
                      inconterm={true}
                      imo={true}
                      servicesAdd={true}
                      // buttonAddCard={key === lastCard}
                      buttonAddCard={false}
                      type={"premium"}
                      key={key}
                      id={key}
                      index={index}
                      removeAnimation={removeCard}
                      addCard={() =>
                        setArrayInternationalRoadPremium([
                          ...arrayInternationalRoadPremium,
                          key + 1,
                        ])
                      }
                      removeCard={() => {
                        setRemoveCard(true);
                        setTimeout(() => {
                          setRemoveCard(false);
                          removeCardQuotation(
                            lastCard,
                            arrayInternationalRoadPremium,
                            setArrayInternationalRoadPremium
                          );
                        }, 580);
                      }}
                      isPrimaryCard={arrayInternationalRoadPremium.length == 1}
                    />
                  );
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "1000px",
                    width: "100%",
                  }}
                >
                  <Button
                    width="225px"
                    disabled={btnDisabled}
                    height="45px"
                    background={"var(--color-primary)"}
                    borderRadius="60px"
                    marginTop="0"
                    name="Concluir cotação"
                    color={"var(--white)"}
                    onClick={() => handleProposal("checkInternationalStandard")}
                    border="none"
                  />
                </div>
              </>
            )}

            {checkDedicatedInternational && (
              <>
                {arrayInternationalRoadDedicated.map((key, index) => {
                  const lastCard =
                    arrayInternationalRoadDedicated[
                      arrayInternationalRoadDedicated.length - 1
                    ];

                  return (
                    <SectionSelectQuotationInformation
                      check={"checkDedicatedInternational"}
                      inconterm={true}
                      imo={true}
                      index={index}
                      // buttonAddCard={key === lastCard}
                      buttonAddCard={false}
                      key={key}
                      id={key}
                      removeAnimation={removeCard}
                      addCard={() =>
                        setArrayInternationalRoadDedicated([
                          ...arrayInternationalRoadDedicated,
                          key + 1,
                        ])
                      }
                      removeCard={() => {
                        setRemoveCard(true);
                        setTimeout(() => {
                          setRemoveCard(false);
                          removeCardQuotation(
                            lastCard,
                            arrayInternationalRoadDedicated,
                            setArrayInternationalRoadDedicated
                          );
                        }, 580);
                      }}
                      isPrimaryCard={
                        arrayInternationalRoadDedicated.length == 1
                      }
                    />
                  );
                })}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "1000px",
                    width: "100%",
                  }}
                >
                  <Button
                    width="225px"
                    disabled={btnDisabled}
                    height="45px"
                    background={"var(--color-primary)"}
                    borderRadius="60px"
                    marginTop="0"
                    name="Concluir cotação"
                    color={"var(--white)"}
                    onClick={() =>
                      handleProposal("checkDedicatedInternational")
                    }
                    border="none"
                  />
                </div>
              </>
            )}

            {storage && (
              <>
                <InsertInformation />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "1000px",
                    width: "100%",
                  }}
                >
                  <Button
                    width="225px"
                    disabled={btnDisabled}
                    height="45px"
                    background={"var(--color-primary)"}
                    borderRadius="60px"
                    marginTop="0"
                    name="Solicitar cotação"
                    color={"var(--white)"}
                    onClick={() => onSubmitStoragenNational()}
                    border="none"
                  />
                </div>
              </>
            )}

            {checkNationalAir && (
              <>
                {arrayAirTransportNationalAir.map((key) => {
                  const lastCard =
                    arrayAirTransportNationalAir[
                      arrayAirTransportNationalAir.length - 1
                    ];

                  return (
                    <SectionSelectQuotationInformation
                      key={key}
                      id={key}
                      check={"checkNationalAir"}
                      removeAnimation={removeCard}
                      isPrimaryCard={arrayAirTransportNationalAir.length == 1}
                    />
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "1000px",
                    width: "100%",
                  }}
                >
                  <Button
                    width="225px"
                    disabled={btnDisabled}
                    height="45px"
                    background={"var(--color-primary)"}
                    borderRadius="60px"
                    marginTop="0"
                    name="Solicitar cotação"
                    color={"var(--white)"}
                    onClick={() => onSubmitCheckAirNational()}
                    border="none"
                  />
                </div>
              </>
            )}

            {checkInternationalAir && (
              <>
                {arrayAirTransportInternationalAir.map((key) => {
                  const lastCard =
                    arrayAirTransportInternationalAir[
                      arrayAirTransportInternationalAir.length - 1
                    ];

                  return (
                    <SectionSelectQuotationInformation
                      inconterm={true}
                      check={"checkInternationalAir"}
                      key={key}
                      id={key}
                      removeAnimation={removeCard}
                    />
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "1000px",
                    width: "100%",
                  }}
                >
                  <Button
                    width="225px"
                    disabled={btnDisabled}
                    height="45px"
                    background={"var(--color-primary)"}
                    borderRadius="60px"
                    marginTop="0"
                    name="Solicitar cotação"
                    color={"var(--white)"}
                    onClick={() => onSubmitCheckAirNational()}
                    border="none"
                  />
                </div>
              </>
            )}

            {maritimeTransport && (
              <>
                {arrayMaritimeTransport.map((key) => {
                  const lastCard =
                    arrayMaritimeTransport[arrayMaritimeTransport.length - 1];

                  return (
                    <SectionSelectQuotationInformation
                      inconterm={true}
                      key={key}
                      id={key}
                      check={"checkMaritime"}
                    />
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    maxWidth: "1000px",
                    width: "100%",
                  }}
                >
                  <Button
                    width="225px"
                    disabled={btnDisabled}
                    height="45px"
                    background={"var(--color-primary)"}
                    borderRadius="60px"
                    marginTop="0"
                    name="Solicitar cotação"
                    color={"var(--white)"}
                    onClick={() => onSubmitCheckMaritimeTransport()}
                    border="none"
                  />
                </div>
              </>
            )}
          </div>

          <ModalContainer
            isActive={modal}
            closeModal={() => {
              setModal(false);
              setVisibleProposal(true);
              setAdditionalModal("");
            }}
          >
            <ModalCompleteQuotation
              additionalModal={additionalModal}
              requestQuotation={requestQuotation}
              onClose={() => {
                setModal(false);
                setVisibleProposal(true);
                setAdditionalModal("");
                setRequestQuotation(false);
              }}
            />
          </ModalContainer>
        </>
      }
    ></ThowColumLayout>
  );
};

export default Dashboard;
