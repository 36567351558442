/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import { yesOrNoAnswer } from "../mocks/defaultAnswers";
import { inconterms } from "../mocks/inconterms";
import api from "../services/api";
import CollectionFtl from "./CollectionFtl";
import InputPrimeReact from "./InputPrimeReact";
import InputsFreightQuoteInternational from "./InputsFreightQuoteInternational";
import MerchandiseDetails from "./MerchandiseDetails";
import SelectPrimeComponent from "./SelectPrimeComponent";
import Textarea from "./Textarea";
import UploadImg from "./UploadImg";

const CardInfoQuotationInternationalFTL = ({
  inconterm,
  type,
  index,
  imo,
  servicesAdd,
}) => {
  const {
    getOrigin,
    getDestination,
    getProfileContext,
    dataCheckFractional,
    setDataCheckFractional,
  } = useContext(DashboardContext);

  const { btnStateInternationFTL } = useContext(ProposalContext);

  /* DATA LOCALE */
  const [dataLocation, setDataLocation] = useState({});

  /* INPUTS VALUE */
  const [originFraction, setOriginFraction] = useState("");

  const [selectDestination, setSelectDestination] = useState([]);
  // const [destination, setDestination] = useState("");

  const [inputActive, setInputActive] = useState(false);
  const [commodityPrice, setCommodityPrice] = useState(0);
  const [merchandiseFraction, setMerchandiseFraction] = useState("");

  const [imagesBase64, setImagesBase64] = useState([]);
  const [imagesFirebase, setImagesFirebase] = useState([]);

  /* BLUE INPUTS */
  const [originBlur, setOriginBlur] = useState("");
  const [destinationBlur, setDestinationBlur] = useState("");
  const [incontermBlur, setIncontermBlur] = useState("");
  const [imoBlur, setImoBlur] = useState("");

  const [typeVehiclesBlur, setTypeVehiclesBlur] = useState("");
  const [cityStateBlur, setCityStateBlur] = useState("");
  const [cityBlur, setCityBlur] = useState("");

  const [palletizedLoadBlur, setPalletizedLoadBlur] = useState("");
  const [kindOfPackingBlur, setKindOfPackingBlur] = useState("");
  const [stackedLoadBlur, setStackedLoadBlur] = useState("");

  const [coinTypeBlur, setCoinTypeBlur] = useState("");
  const [merchandiseBlur, setMerchandiseBlur] = useState("");
  const [commodityPriceBlur, setCommodityPriceBlur] = useState("");

  /* QUOTATION SUMMARY */
  const [freightWeightValue, setFreightWeightValue] = useState(0);
  const [dispatchValue, setdispatchValue] = useState(0);
  const [sefazTaxValue, setSefazTaxValue] = useState(0);

  const [advaloremValue, setAdvaloremValue] = useState(0);
  const [tollValue, setTollValue] = useState(0);

  const [cubageTotal, setCubageTotal] = useState(0);
  const [listTypeVehicles, setListTypeVehicles] = useState([]);
  const [typeVehiclesSelect, setTypeVehiclesSelect] = useState("");
  const [originAdress, setOriginAdress] = useState("");
  const [destinationAdress, setDestinationAdress] = useState("");

  const [updateValue, setUpdateValue] = useState(false);
  const [cityList, setCityList] = useState([]);

  const [originInternarional, setOriginInternarional] = useState("");
  const [destinationInternational, setDestinationInternational] = useState("");
  const [statesList, setStatesList] = useState([]);

  const [incontermInternational, setIncontermInternational] = useState("");
  const [imoInternational, setImoInternational] = useState("");
  const [originFractionInternational, setOriginFractionInternational] =
    useState("");

  const [emissionClearance, setEmissionClearance] = useState("");
  const [issuanceCertifOrigin, setIssuanceCertifOrigin] = useState("");

  const [additionalIssuanceCertifOrigin, setAdditionalIssuanceCertifOrigin] =
    useState("");
  const [helperFee, setHelperFee] = useState("");
  const [priceTotalStanded, setPriceTotalStanded] = useState("");
  const [priceTotalPremium, setPriceTotalPremium] = useState("");

  const [addressInternarional, setAddressInternarional] = useState("");
  const [addressInternarionalState, setAddressInternarionalState] =
    useState("");
  const [addressInternarionalOptional, setAddressInternarionalOptional] =
    useState("");

  const [
    addressInternarionalOptionalActive,
    setAddressInternarionalOptionalActive,
  ] = useState(false);

  const [kindOfPacking, setKindOfPacking] = useState("");
  const [palletizedLoad, setPalletizedLoad] = useState("");
  const [stackedLoad, setStackedLoad] = useState("");

  const [coinType, setCoinType] = useState("");
  const [originOption, setOriginOption] = useState([]);

  const [emissionClearancePrice, setEmissionClearancePrice] = useState("");
  const [issuanceCertifOriginPrice, setIssuanceCertifOriginPrice] =
    useState("");
  const [
    additionalIssuanceCertifOriginPrice,
    setAdditionalIssuanceCertifOriginPrice,
  ] = useState("");

  const [helperFeePrice, setHelperFeePrice] = useState("");
  const [hasRenderedOnce, setHasRenderedOnce] = useState(false);
  const [formOfPayment, setFormOfPayment] = useState("");
  const [allStatesAndCitysToBrazil, setAllStatesAndCitysToBrazil] = useState(
    []
  );

  const [disableStateSelection, setDisableStateSelection] = useState(false);
  const [originDestinationExemplo, setOriginDestinationExemplo] = useState("");
  const [originOrDestine, setOriginOrDestine] = useState("");

  const [multiLineCubing, setMultiLineCubing] = useState("");
  const [isMultiLineCubing, setIsMultiLineCubing] = useState(false);
  const [dimensions, setDimensions] = useState([]);

  // NOVO ---------------------------------------------------------
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [isNational, setIsNational] = useState(null);
  const [incontermsFTL, setIncontermsFTL] = useState("");
  const [imoFTL, setImoFTL] = useState("");

  const [originOptionsFTL, setOriginOptionsFTL] = useState([]);
  const [destinationOptionsFTL, setDestinationOptionsFTL] = useState([]);
  const [vehiclesOptionsFTL, setVehiclesOptionsFTL] = useState([]);

  const [stateSelectUser, setStateSelectUser] = useState("");
  const [city, setCity] = useState("");

  const [merchandiseDetails, setMerchandiseDetails] = useState({
    merchandiseType: "",
    palletizedLoad: null,
    packagingType: null,
    stackedLoad: null,
    currency: null,
    merchandiseValue: null,
  });

  const [dataSummaryResume, setDataSummaryResume] = useState({
    cubageTotal: 0,
    m3: "0 m³",
    grossWeight: 0,
    quantity: 0,
    grossWeight2: 0,
    weightCubed2: 0,
    cubageWeight: 0,
    total: 0,
  });

  const [detailsQuotation, setDetailsQuotation] = useState({
    id: "",
    peso_ranges_kg: "",
    cubagem: "",

    origin_city_state: "",
    origin_address: "",
    country: "",
    destino_city_state: "",
    destination_address: "",
    country1: "",
  });

  // --------------------------------------------------------------

  // NOVO -----------------------------------------------------
  const getOriginsFTL = async () => {
    try {
      const { data } = await api.get("/quotations/ftl/origin");

      const formact = data?.map((elem) => ({
        label: elem.origin,
        value: { origin: elem.origin, isNational: elem.isNational },
      }));

      setOriginOptionsFTL(formact);
    } catch (error) {}
  };

  const getDestinationFTL = async (originSelect) => {
    try {
      const { data } = await api.get(
        `/quotations/ftl/destinations?origin=${originSelect.origin}`
      );

      setOrigin(originSelect.origin);

      onSelectStateOrigin({
        origemParametro: originSelect.origin,
        isNational,
        destinoParametro: "Sem destino",
      });
      setDestination("");
      setVehicle("");
      setIsNational(originSelect.isNational);

      const formact = data?.map((elem) => ({
        label: elem.destiny.split(),
        value: elem.destiny,
      }));

      setDestinationOptionsFTL(formact);
    } catch (error) {}
  };

  const getVehiclesFTL = async (destinySelect) => {
    try {
      const { data } = await api.get(
        `/quotations/ftl/vehicles?origin=${origin}&destiny=${destinySelect}`
      );

      onSelectStateOrigin({
        origemParametro: origin,
        isNational,
        destinoParametro: destinySelect,
      });
      setDestination(destinySelect);
      setVehicle("");

      const formact = data?.map((elem) => ({
        label: elem.vehicles.split(),
        value: elem.vehicles,
      }));

      setVehiclesOptionsFTL(formact);
    } catch (error) {}
  };

  const getDetails = async (vehicleSelect) => {
    try {
      const { data } = await api.get(
        `/quotations/ftl/details?origin=${origin}&destiny=${destination}&vehicles=${vehicleSelect}`
      );
      setVehicle(vehicleSelect);
      console.log(data);

      setDetailsQuotation(data);
    } catch (error) {}
  };

  // Função para remover pontuação
  const removePunctuation = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/gi, "")
      .toLowerCase();
  };

  // FUNÇÃO DEIXA PRE SETADO O ESTADO NO CAMPO 'SELECIONAR ESTADO'
  const onSelectStateOrigin = ({
    origemParametro,
    isNational,
    destinoParametro,
  }) => {
    console.log(origemParametro, isNational, destinoParametro);
    const origemSemPontuacao = removePunctuation(origemParametro).trim();
    const destinoSemPontuacao = removePunctuation(destinoParametro).trim();

    const filterStateSelectOrigin = allStatesAndCitysToBrazil.find(
      (states) => removePunctuation(states.nome) === origemSemPontuacao
    );

    const filterStateSelectDestination = allStatesAndCitysToBrazil.find(
      (states) => removePunctuation(states.nome) === destinoSemPontuacao
    );

    const formactStateOrigin =
      filterStateSelectOrigin?.microrregiao?.mesorregiao?.UF;

    const formactStateDestine =
      filterStateSelectDestination?.microrregiao?.mesorregiao?.UF;

    if (isNational == true && formactStateOrigin && !formactStateDestine) {
      setDisableStateSelection(true);
      getCityInBrazil("", formactStateOrigin);

      return true;
    } else if (
      isNational == true &&
      formactStateOrigin &&
      formactStateDestine
    ) {
      setDisableStateSelection(true);
      getCityInBrazil("", formactStateOrigin);
      return true;
    } else if (isNational == false && formactStateDestine) {
      setDisableStateSelection(true);
      getCityInBrazil("", formactStateDestine);

      return true;
    } else {
      setDisableStateSelection(false);
      return false;
    }
  };

  const getSelect = async (e) => {
    if (e.target.value != "default") {
      // USAR COM PRIME REACT
      const result = await getOrigin(e.value.nome, index);

      console.log(e.value.nome);
      setCity(e.value.nome);

      setOriginDestinationExemplo(e.value);

      setSelectDestination({
        option: result.national,
      });
    } else {
      setSelectDestination({});
    }
  };

  const getStatesInBrazil = async () => {
    try {
      const getStates = await axios.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
      );

      setStatesList(getStates.data);
    } catch (error) {}
  };

  const getCityInBrazil = async (e, stateFormact) => {
    try {
      const state = e?.target?.value ?? stateFormact;
      console.log(stateFormact, 100);
      setStateSelectUser(state);
      // setOriginDestination("");
      const getCitys = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state.sigla}/municipios?orderBy=nome`
      );

      console.log(getCitys);
      setCityList(getCitys.data);
    } catch (error) {}
  };

  const getQuotationDetails = async () => {
    try {
      let dataState = dataCheckFractional;
      dataState[index] = {
        id: detailsQuotation?.id,
        inconterm: incontermsFTL,
        cargaImo: imoFTL,
        cidadeEntrega: stateSelectUser?.nome || stateSelectUser,
        enderecoEntrega: city,
        origemNacional: isNational,
        tipoMercadoria: merchandiseDetails.merchandiseType,
        cargaPaletizada: merchandiseDetails.palletizedLoad,
        tipoEmbalagem: merchandiseDetails.packagingType,
        cargaEmpilhada: merchandiseDetails.stackedLoad,
        moeda: merchandiseDetails.currency,
        valorMercadoria: merchandiseDetails.merchandiseValue,
        peso: Math.max(
          dataSummaryResume?.total,
          dataSummaryResume?.cubageWeight
        ),
        pesoCliente: dataSummaryResume?.grossWeight,

        quantity: dataSummaryResume.quantity,
        cubagem: dataSummaryResume?.m3,
        cubageNumero: dataSummaryResume.cubageTotal,
        multiplasCubagens: {
          quantidade: multiLineCubing?.quantitySum,
          peso: multiLineCubing?.weightCubedSum,
        },
        dimenssoes: dimensions,
        formaPagamento: formOfPayment,
      };

      if (isMultiLineCubing) {
        dataState[index].peso = multiLineCubing?.weightCubedSum;

        dataState[index].cubage = `${Number(multiLineCubing?.cubageSum).toFixed(
          2
        )} m³`;

        dataState[index].cubageNumber = multiLineCubing?.cubageSum;
        dataState[index].quantity = multiLineCubing?.quantitySum;
        dataState[index].typePacking =
          multiLineCubing?.hasWood == true
            ? "Caixas de madeira"
            : kindOfPacking;
        console.log(dataState, 2000);
      }

      console.log(originOrDestine);

      console.log(dataSummaryResume);
      const response = await api.post(
        `/quotations/international/ftl/all`,
        dataState[index]
      );

      if (response?.data?.message) {
        toast.info(`${response?.data?.message}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const {
          freight,
          adValoremGris,
          total,
          peso,
          coinToday,
          formOfPayment,
          exceededKmLimit,
          distanceInKm,
          details,
        } = response.data;
        dataState[index].freight = freight;
        dataState[index].adValoremGris = adValoremGris;
        dataState[index].totalPriceMoeda = total;
        dataState[index].peso = peso;
        dataState[index].coinToday = coinToday;
        dataState[index].formOfPayment = formOfPayment;
        dataState[index].exceededKmLimit = exceededKmLimit;
        dataState[index].distanceInKm = distanceInKm;
        dataState[index].origemProposta = details.origemProposta;
        dataState[index].destinoProposta = details?.destinoProposta;

        if (exceededKmLimit) {
          setdispatchValue(0);
          setSefazTaxValue(0);
          setFreightWeightValue(0);
          setCubageTotal();
        } else {
          setdispatchValue(freight);
          setSefazTaxValue(adValoremGris);
          setFreightWeightValue(total);
          setCubageTotal();
        }
        setDataCheckFractional(dataState);
      }
      console.log(response.data);
    } catch (error) {}
  };

  const getOrigins = async (e) => {
    try {
      const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
      const decoded = jwt_decode(token);
      const { id } = decoded;
      const client = await getProfileContext(id);

      const response = await api.get(
        `/quotations/international/origin/ftl/${client.email}`
      );

      setOriginOption(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Função para buscar a lista de municípios do IBGE
  const fetchStates = async () => {
    try {
      const response = await axios.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/municipios"
      );
      const municipios = response.data;

      setAllStatesAndCitysToBrazil(municipios);
    } catch (error) {
      console.error("Erro ao buscar estados:", error);
    }
  };

  // NOVO ----------------------------------------------------
  useEffect(() => {
    getOriginsFTL();
  }, []);

  useEffect(() => {
    if (hasRenderedOnce) {
      getQuotationDetails();
    }
    setHasRenderedOnce(true);
  }, [btnStateInternationFTL]);

  useEffect(() => {
    getStatesInBrazil();
    getOrigins();
    fetchStates();
  }, []);

  // ---------------------------------------------------------

  const boxInputs = {
    display: "flex",
    gap: "20px",
  };

  const containerContent = {
    backgroundColor: "#fff",
    width: "100%",
    padding: "32px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginTop: "30px",
  };

  return (
    <>
      <h3 className="textTitleTypeDispatch">Informações da cotação</h3>
      <div style={{ ...boxInputs, marginTop: "20px" }}>
        <SelectPrimeComponent
          label={"Rota comercial de origem"}
          options={originOptionsFTL}
          onChange={getDestinationFTL}
        />
        <SelectPrimeComponent
          selectedValue={destination}
          label={"Rota comercial de destino"}
          options={destinationOptionsFTL}
          onChange={getVehiclesFTL}
        />

        {inconterm && (
          <SelectPrimeComponent
            label={"Inconterms"}
            options={inconterms}
            onChange={setIncontermsFTL}
            searchable
            width={230}
          />
        )}
        {imo && (
          <SelectPrimeComponent
            label={"Carga imo"}
            options={yesOrNoAnswer}
            onChange={setImoFTL}
            width={140}
          />
        )}
      </div>

      <CollectionFtl
        typeDestiny={"Origem da mercadoria"}
        optionsVehicles={vehiclesOptionsFTL}
        onChangeVehicles={getDetails}
        selectedVehicles={vehicle}
        // Estados
        optionsStates={statesList}
        onChangeStates={getCityInBrazil}
        valueState={stateSelectUser}
        disabledState={disableStateSelection}
        // Cidades
        optionsCitys={cityList}
        onChangeCitys={getSelect}
        valueCity={originDestinationExemplo}
      />

      <MerchandiseDetails
        formData={merchandiseDetails}
        setFormData={setMerchandiseDetails}
      />

      <div style={boxInputs}>
        {
          <InputsFreightQuoteInternational
            updateCubage={() => setUpdateValue(!updateValue)}
            isActive={true}
            sumResume={dataSummaryResume}
            setSumResume={setDataSummaryResume}
            index={index}
            multiLineCubing={multiLineCubing}
            setMultiLineCubing={setMultiLineCubing}
            setIsMultiLineCubing={setIsMultiLineCubing}
            isMultiLineCubing={isMultiLineCubing}
            setDimensions={setDimensions}
          />
        }
      </div>

      <div style={{ marginBottom: "20px" }}>
        <h3 className="textTitleTypeDispatch">Pagamento</h3>

        <div style={{ width: "40%" }}>
          <InputPrimeReact
            label={"Escolha a forma de pagamento"}
            value={formOfPayment}
            onChange={(e) => setFormOfPayment(e.value)}
            type={"dropdown"}
            options={[
              "Boleto bancário - BRL",
              "Crédito em conta - BRL/ARS",
              "Pix à vista com desconto 1% - BRL",
              "Swift Bancário - Remessa ao exterior - USD",
              "ALIAS à vista com desconto de 1% - ARS",
            ]}
            placeholder={"Selecione"}
            width={"100%"}
            className={"currencyTable"}
            currencyType={coinType}
          />
        </div>
      </div>

      <div>
        <Textarea name="Observações adicionais" height="83px" width="100%" />
      </div>

      <UploadImg
        imagesBase64={imagesBase64}
        setImagesBase64={setImagesBase64}
        imagesFirebase={imagesFirebase}
        setImagesFirebase={setImagesFirebase}
      />

      <div style={containerContent}>
        <div>
          <h3 className="textTitleTypeDispatch">Resumo total cotação</h3>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ display: "flex", width: "146px" }}>
            <div>
              <p className="textTitleSummary">Frete</p>
              <div>
                {dispatchValue ? (
                  dispatchValue
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "18px", width: "195px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Ad Valorem | Gris</p>
              <div>
                {sefazTaxValue ? (
                  sefazTaxValue
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "50px", width: "200px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Total</p>
              <div>
                {freightWeightValue ? (
                  freightWeightValue
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "50px", width: "209px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Cubagem</p>
              <div>
                {cubageTotal ? (
                  cubageTotal
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ display: "flex", gap: "50px", width: "342px" }}>
            <div>
              <p className="textTitleSummary">
                Valor total (Standard) | Transit time - 102 Dias
              </p>
              <div>
                {priceTotalStanded ? (
                  priceTotalStanded
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "14px", width: "387px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">
                Valor total (Premium) | Transit time - 5 Dias
              </p>
              <div>
                {priceTotalPremium ? (
                  priceTotalPremium
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default CardInfoQuotationInternationalFTL;
