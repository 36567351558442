import { useContext, useEffect } from "react";
import { useState } from "react";
import Input from "./Input";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import api from "../services/api";
import InputPrimeReact from "./InputPrimeReact";
import infoIcon from "../images/png/info.png";
import ModalContainer from "./ModalContainer";
import ModalGroupingMode from "./ModalGroupingMode";

const InputsFreightQuoteInternational = ({
  sumResume,
  setSumResume,
  index,
  isActive,
  updateCubage,
  setMultiLineCubing,
  setIsMultiLineCubing,
  isMultiLineCubing,
  setDimensions,
  multiLineCubing,
  national,
}) => {
  const {
    update,
    setUpdate,
    cubageCheckFractional,
    setCubageCheckFractional,
    setDataCheckFractional,
  } = useContext(DashboardContext);

  const [quantityCheckFractional, setQuantityCheckFractional] = useState("");
  const [grossWeightCheckFractional, setGrossWeightCheckFractional] =
    useState("");
  const [heightCheckFractional, setHeightCheckFractional] = useState("");
  const [widthCheckFractional, setWidthCheckFractional] = useState("");
  const [lengthCheckFractional, setLengthCheckFractional] = useState("");
  const [cubedWeight, setCubedWeight] = useState("");
  const [isModalActive, setIsModalActive] = useState(false);

  const calculateCubage = async (length, width, height) => {
    const cubage = (Number(length) * Number(width) * Number(height)) / 1000000;
    const result = cubage * quantityCheckFractional * 1000000;
    const formattedResult = result.toLocaleString("pt-BR");

    const sum = {
      cubageTotal: Number(result.toFixed(2)),
      m3: `${formattedResult} m³`,
      grossWeight: grossWeightCheckFractional,
      quantity: Number(quantityCheckFractional),
      grossWeight2: grossWeightCheckFractional,
      weightCubed2: cubedWeight,
    };

    if (sum.cubageTotal > 0) {
      const url = national
        ? "/discounts/quotation/cubed"
        : "/discounts/quotation/cubed/international";
      const resultSum = await api.post(url, { cubageTotal: sum.cubageTotal });

      console.log(resultSum);
      sum.cubageWeight = resultSum.data.result;

      // if (resultSum?.data?.result?.includes(".")) {
      //   sum.cubageWeight = Number(
      //     resultSum.data.result.replaceAll(",", ".").replace("kg", "")
      //   );
      // }
      sum.total =
        grossWeightCheckFractional > Number(resultSum.data.result)
          ? grossWeightCheckFractional
          : Number(resultSum.data.result);

      sum.weightCubed2 = resultSum.data.result;

      setCubedWeight(resultSum.data.result);
    } else {
      setCubedWeight(0);
    }

    if (Number(sum?.weightCubed2)) {
      updateCubage();
    }

    setSumResume(sum);

    setUpdate(!update);

    if (sum.m3) setCubageCheckFractional(`${formattedResult} m³`);

    return cubage.toFixed(2);
  };

  const getUpdateCubage = () => {
    updateCubage();
  };

  useEffect(() => {
    updateCubage();
  }, []);

  useEffect(() => {
    calculateCubage(
      lengthCheckFractional,
      widthCheckFractional,
      heightCheckFractional
    );
  }, [
    quantityCheckFractional,
    grossWeightCheckFractional,
    heightCheckFractional,
    widthCheckFractional,
    lengthCheckFractional,
    // cubageCheckFractional,
  ]);

  useEffect(() => {
    if (isMultiLineCubing) {
      setTimeout(() => {
        setIsModalActive(true);
      }, 500);
    }
  }, [isMultiLineCubing]);

  useEffect(() => {
    if (isMultiLineCubing) {
      const cubageMultiLine = multiLineCubing?.cubageSum
        ? `${multiLineCubing?.cubageSum} m³`
        : `0 m³`;

      const cubedWeightMultiLine = multiLineCubing?.weightCubedSum
        ? multiLineCubing?.weightCubedSum
        : 0;

      setCubedWeight(cubedWeightMultiLine);
      setCubageCheckFractional(cubageMultiLine);
    }
  }, [isMultiLineCubing, isModalActive, multiLineCubing]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        gap: "20px",
        marginBottom: "15px",
      }}
    >
      <Input
        isActive={isMultiLineCubing ? false : isActive}
        value={quantityCheckFractional}
        onChange={(e) => setQuantityCheckFractional(e.target.value)}
        name="Quantidade"
        width="100%"
        placeholder=""
        type={"number"}
      />

      <InputPrimeReact
        disabled={!isActive}
        label={"Peso bruto"}
        value={grossWeightCheckFractional}
        onChange={(e) => setGrossWeightCheckFractional(e.value)}
        type={"number"}
        className={"pesoCubage"}
        minFractionDigits={1}
        suffix={" kg"}
      />

      <Input
        isActive={isMultiLineCubing ? false : isActive}
        // value={heightCheckFractional.replace(/,/g, ".")}
        onBlur={(e) => setHeightCheckFractional(e.target.value)}
        name="Altura (M)"
        placeholder="Ex: 1"
        width="100%"
        type={"number"}
      />
      <Input
        isActive={isMultiLineCubing ? false : isActive}
        // value={widthCheckFractional.replace(/,/g, ".")}
        onBlur={(e) => setWidthCheckFractional(e.target.value)}
        name="Largura (M)"
        placeholder="Ex: 1,50"
        width="100%"
        type={"number"}
      />
      <Input
        isActive={isMultiLineCubing ? false : isActive}
        // value={lengthCheckFractional.replace(/,/g, ".")}
        onBlur={(e) => setLengthCheckFractional(e.target.value)}
        name="Comprimento (M)"
        placeholder="Ex: 2"
        width="100%"
        type={"number"}
      />
      <Input
        isActive={isMultiLineCubing ? false : isActive}
        value={cubageCheckFractional}
        onChange={(e) => setCubageCheckFractional(e.target.value)}
        name="Cubagem M³"
        placeholder=""
        width="100%"
        type={"text"}
      />

      <InputPrimeReact
        disabled
        label={"Peso cubado"}
        value={cubedWeight}
        type={"number"}
        width={"300px"}
        className={"cubage"}
        suffix={" kg"}
      />
      <div
        style={{ position: "absolute", right: 0, top: 100, cursor: "pointer" }}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            value={isMultiLineCubing}
            style={{ width: 15, height: 15 }}
            onClick={() => setIsMultiLineCubing(!isMultiLineCubing)}
          />
          <p>Adicionar multiplas dimensões?</p>
        </div>
        {/* <img src={infoIcon} style={{ width: "15px", objectFit: "contain" }} /> */}
      </div>

      <ModalContainer
        isActive={isModalActive}
        closeModal={() => setIsModalActive(true)}
        opacity={60}
      >
        <ModalGroupingMode
          setMultiLineCubing={setMultiLineCubing}
          setDimensions={setDimensions}
          setQuantity={setQuantityCheckFractional}
          closeModal={() => setIsModalActive(false)}
        />
      </ModalContainer>
    </div>
  );
};

export default InputsFreightQuoteInternational;
