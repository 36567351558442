import { useContext, useEffect, useState } from "react";
import CubageRow from "./CubageRow";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import api from "../services/api";
import closeIcon from "../images/svg/closeIcon.svg";

const ModalGroupingMode = ({
  setMultiLineCubing,
  setDimensions,
  closeModal,
  national,
  setQuantity,
}) => {
  const { rowsLTL, setRowsLTL } = useContext(DashboardContext);
  const [totalCubage, setTotalCubage] = useState(0);
  const [totalWeightCubed, setTotalWeightCubed] = useState(0);
  const [hasInitialized, setHasInitialized] = useState(false);

  const buttonStyles = {
    padding: "10px 20px",
    backgroundColor: "var(--color-primary)",
    color: "#fff",
    borderRadius: "8px",
    border: "none",
  };

  const handleAddRow = () => {
    setRowsLTL([
      ...rowsLTL,
      { id: Date.now(), cubage: 0, weightCubed: 0, quantity: 0 },
    ]);
  };

  const handleRemoveRow = () => {
    if (rowsLTL.length > 1) {
      const newRows = rowsLTL.slice(0, -1);
      setRowsLTL(newRows);

      const lengthSum = newRows.reduce(
        (acc, row) => acc + parseFloat(row?.length),
        0
      );

      const widthSum = newRows.reduce(
        (acc, row) => acc + parseFloat(row?.width),
        0
      );

      const heightSum = newRows.reduce(
        (acc, row) => acc + parseFloat(row?.height),
        0
      );

      const cubage = Number(lengthSum) * Number(widthSum) * Number(heightSum);

      const weightCubedSum = newRows.reduce(
        (acc, row) => acc + row.weightCubed,
        0
      );

      if (!isNaN(cubage)) {
        setTotalCubage(cubage);
      } else {
        setTotalCubage(0);
      }

      setTotalWeightCubed(weightCubedSum);
    }
  };

  const handleUpdateRow = async (index, data) => {
    const newRows = [...rowsLTL];
    newRows[index] = { ...newRows[index], ...data };
    setRowsLTL(newRows);

    console.log(newRows);

    const dimensionsFilter = newRows
      ?.filter((elem) => elem?.kindOfPacking)
      ?.map((elem) => {
        return {
          kindOfPacking: elem.kindOfPacking,
          height: elem.height,
          width: elem.width,
          length: elem.length,
          cubage: elem.cubage,
          quantity: elem.quantity,
        };
      });

    const hasWood = newRows.some((item) =>
      item.kindOfPacking.toLowerCase().includes("madeira")
    );

    console.log(dimensionsFilter);

    setDimensions(dimensionsFilter);

    let cubageSum = 0;

    const quantitySum = newRows.reduce(
      (acc, row) => acc + Number(row.quantity),
      0
    );

    const weightCubedSum = newRows.reduce(
      (acc, row) => acc + row.weightCubed,
      0
    );

    const cubage = newRows.reduce(
      (acc, row) => acc + parseFloat(row?.cubage),
      0
    );

    if (!isNaN(cubage)) {
      console.log(cubageSum);
      cubageSum = cubage;
    } else {
      cubageSum = 0;
    }

    if (!isNaN(cubage)) {
      const url = national
        ? "/discounts/quotation/cubed"
        : "/discounts/quotation/cubed/international";
      console.log(url);

      const resultSum = await api.post(url, { cubageTotal: cubage });

      console.log(resultSum);

      setTotalWeightCubed(resultSum.data);
    }

    if (setQuantity) {
      setQuantity(quantitySum);
    }

    setMultiLineCubing({
      cubageSum,
      weightCubedSum,
      quantitySum,
      hasWood: hasWood,
    });

    console.log({
      cubageSum,
      weightCubedSum,
      quantitySum,
      hasWood: hasWood,
    });

    setTotalCubage(cubageSum);
    setTotalWeightCubed(weightCubedSum);
  };

  useEffect(() => {
    setRowsLTL([{ id: Date.now(), cubage: 0, weightCubed: 0 }]);
  }, []);

  return (
    <div
      style={{
        width: "700px",
        height: "520px",
        backgroundColor: "#fff",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        border: "1px solid #d7d7d7",
        borderRadius: "10px",
      }}
    >
      <div style={{ position: "relative" }}>
        <h5 className="textMutipleMeasure">Adicione as medidas</h5>

        <div
          onClick={closeModal}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            cursor: "pointer",
            width: "30px",
            textAlign: "center",
          }}
        >
          <img src={closeIcon} />
        </div>
      </div>
      <div
        style={{
          height: "300px",
          overflowY: "auto",
          paddingRight: 5,
        }}
      >
        {rowsLTL.map((row, index) => (
          <CubageRow
            key={row.id}
            data={row}
            national={national}
            onUpdate={(data) => handleUpdateRow(index, data)}
          />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "space-between",
        }}
      >
        <button style={buttonStyles} onClick={handleAddRow}>
          Adicionar Linha
        </button>
        <button
          style={{
            ...buttonStyles,
            backgroundColor: "#AD0309",
            opacity: rowsLTL.length <= 1 ? 0.5 : 1,
          }}
          onClick={handleRemoveRow}
          disabled={rowsLTL.length <= 1}
        >
          Remover Última Linha
        </button>
        <button style={buttonStyles} onClick={closeModal}>
          Concluir
        </button>
      </div>
      <div
        style={{
          display: "flex",
          gap: "40px",
          borderTop: "1px solid #d7d7d7",
          paddingTop: "20px",
        }}
      >
        <p style={{ fontSize: "15px" }}>
          Total Cubagem: {totalCubage?.toFixed(2)} m³
        </p>
        <p style={{ fontSize: "15px" }}>
          Total Peso Cubado: {totalWeightCubed?.toFixed(2)} kg
        </p>
      </div>
    </div>
  );
};

export default ModalGroupingMode;
