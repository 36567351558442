import axios from "axios";

const api = axios.create({
  // baseURL: "http://198.199.88.62",
  // baseURL: "https://backend.maxitrans.com.br",
  // baseURL: "https://hammerhead-app-kmkr5.ondigitalocean.app",
  // baseURL: "http://localhost:8081",

  baseURL: "https://backend.maxitrans.com.br",
  timeout: 30000,
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
    const tokenAdmin = localStorage.getItem("@MAXITRANS_TOKEN_ADMIN:");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else if (tokenAdmin) {
      config.headers["Authorization"] = `Bearer ${tokenAdmin}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
