import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./styles/animations.css";
import "./styles/hover.css";
import "./styles/active.css";
import { RoutesUrl } from "./routes/routes";
import { AuthValidation } from "./context/Auth/AuthContext";
import { ToastContainer } from "react-toastify";
import { DashboardValidation } from "./context/Dashboard/DashboardContext";
import { UserValidation } from "./context/User";
import ModalProvider from "./context/Modal";
import { ProposalValidation } from "./context/Dashboard/ProposalContext";

const App = () => {
  return (
    <>
      <DashboardValidation>
        <ProposalValidation>
          <UserValidation>
            <ModalProvider>
              <AuthValidation>
                <RoutesUrl />
              </AuthValidation>
            </ModalProvider>
          </UserValidation>
        </ProposalValidation>
      </DashboardValidation>
      <ToastContainer />
    </>
  );
};

export default App;
