import { useState, useEffect } from "react";
import Input from "./Input";
import InputSelectFilterManager from "./InputSelectFilterManager";
import InputSelectUsers from "./InputSelectUsers";

const CardDetailsView = ({
  valueSelect,
  onChange,
  onChangeSelect,
  value,
  listDiscount,
  setListDiscount,
  id,
  typeSelect,
  details,
}) => {
  const [selectValue, setSelectValue] = useState("");

  useEffect(() => {
    setSelectValue(valueSelect);
  }, [valueSelect]);

  return (
    <div
      className="boxContainerCard"
      style={{
        width: "100%",
        backgroundColor: "#F9F9F9",
        display: "flex",
        justifyContent: "center",
        paddingTop: "39px",
      }}
    >
      <div
        style={{
          border: "1px solid #d7d7d7",
          width: "1000px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "24px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {selectValue && (
            <InputSelectFilterManager
              value={selectValue}
              onChange={onChangeSelect}
              color="var(--grey-1)"
              name="Tipo"
              width="300px"
              top="50px"
              custom="false"
              type="typeFreight"
              marginBottom="10px"
            />
          )}
          <Input
            onChange={onChange}
            value={value}
            name="Nome"
            width="100%"
            placeholder=""
            type={"text"}
            marginBottom="10px"
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <InputSelectUsers
            id={id}
            listDiscount={listDiscount}
            setListDiscount={setListDiscount}
            name={"Visibilidade"}
            width={"100%"}
          />
        </div>
      </div>
    </div>
  );
};

export default CardDetailsView;
